import React from 'react'
import Icon from '@/components/Icon'
import { Typography } from '@/views/typography'
import { TEquipStatus } from '@/api/interfaces'

import styles from './styles/index.module.scss'

interface IEditStatus {
  status: TEquipStatus
  icon: string
  label: string
}

/**
 * Статус иконка
 * @view
 * @param status - статус
 * @param icon - иконка
 * @param label - лейбл
 * @constructor
 */
const EditStatus: React.FC<IEditStatus> = ({
  status,
  icon,
  label
}) => {
  return (
    <div className={styles['edit-status']}>
      <div
        data-status={status}
        className={styles['edit-status__identifier']}
      >
        <Icon
          className={styles['edit-status__icon']}
          src={icon}
          size='xl'
        />
      </div>
      <Typography size={32} weight={700}>
        {label}
      </Typography>
    </div>
  )
}

export default EditStatus
