import React from 'react'
import { useIntl } from 'react-intl'
import classNames from 'classnames'
import Icon from '../Icon'
import { IStorageBadge } from './interfaces'
import styles from './styles/index.module.scss'

/**
 * @component StorageBadge
 * * компонент значка c названием шкафа
 * @param {IStorageBadge}      общий интерфейс входных параметров StorageBadge
 *
 * @param size              размер
 * @param title             название (контент)
 * @param className         дополнительные стили
 */
const StorageBadge: React.FC<IStorageBadge> = ({
  size = 'medium',
  title = '',
  className = ''
}) => {
  const intl = useIntl()
  const storageTitle = title !== '' ? title : intl.formatMessage({ id: 'storage.no_title', defaultMessage: 'No' })
  return (
    <div className={classNames({
      [styles.badge]: true,
      [styles[`badge--${size}`]]: size,
      [styles['badge--empty']]: title.length === 0,
      [className]: Boolean(className)
    })}
    >
      <Icon src='storage' />
      <span className={styles[`badge--${size}_text`]}>
        {storageTitle}
      </span>
    </div>
  )
}

export default StorageBadge
