import AbsractApi from '@/api/abstract'
import {
  IAxiosConfig,
  IBindUserValue,
  IQueryParams,
  IStorageApi,
  IStorageCellsList,
  IStorageList,
  IStorageSerializedOption,
  IStorageSettings,
  IStorageSettingsOption,
  IStoragesQueryParams,
  IUnbindStorageEquipmentValue,
  IUnbindUserStorageValue,
  ICellFilterParams,
  IDownloadReportParams,
  IUnbinedUsersList,
  TStorageChangedValue,
  TStorageCreateValue,
  TStorageGetValue,
} from '@/api/interfaces'
import {
  DEFAULT_CELL_PAGE_SIZE,
  DEFAULT_CURRENT_PAGE,
  DEFAULT_PAGE_SIZE,
  EMPTY_CONSTS,
} from '@/const'
import { serializeStorageOptions } from '@/utils'

class StorageApi implements IStorageApi {
  ctx: AbsractApi

  constructor(ctx: AbsractApi) {
    this.ctx = ctx
  }

  public getStorages = async (
    param?: IStoragesQueryParams,
    config?: IAxiosConfig,
  ): Promise<IStorageList> => {
    const res = await this.ctx.get<IStorageList>(
      '/storage/',
      {
        ...param,
        page: param?.page ?? DEFAULT_CURRENT_PAGE,
        page_size: param?.page_size ?? DEFAULT_PAGE_SIZE,
      },
      config,
    )
    return res.data
  }

  public getStorage = async (
    id: number,
  ): Promise<TStorageGetValue> => {
    const res = await this.ctx.get<TStorageGetValue>(
      `/storage/${id}/`,
    )
    return res.data
  }

  public createStorage = async (
    value: TStorageCreateValue,
  ): Promise<TStorageGetValue> => {
    const res = await this.ctx.post<TStorageGetValue>(
      '/storage/',
      value,
    )
    return res.data
  }

  public changeStorage = async (
    id: number,
    value: TStorageCreateValue,
  ): Promise<TStorageChangedValue> => {
    const res = await this.ctx.patch<TStorageChangedValue>(
      `/storage/${id}/`,
      value,
    )
    return res.data
  }

  public getStorageCells = async (
    id: number,
    param?: ICellFilterParams,
  ): Promise<IStorageCellsList> => {
    const res = await this.ctx.get<IStorageCellsList>(
      `/storage/${id}/all_cell/`,
      {
        ...param,
        page: param?.page ?? DEFAULT_CURRENT_PAGE,
        page_size:
          param?.page_size ?? DEFAULT_CELL_PAGE_SIZE,
      },
    )
    return res.data
  }

  public getUntetheredUsers = async (
    id: number,
    params?: IQueryParams,
  ): Promise<IUnbinedUsersList> => {
    const res = await this.ctx.get<IUnbinedUsersList>(
      `/storage/${id}/users_who_can_be_tied/`,
      params,
    )
    return res.data
  }

  public bindUserToStorage = async (
    id: number,
    users: IBindUserValue,
  ): Promise<void> => {
    const res = await this.ctx.post<any>(
      `/storage/${id}/tied_users/`,
      users,
    )
    return res.data
  }

  public unbindStorageEquipment = async (
    id: number,
    value: IUnbindStorageEquipmentValue,
  ): Promise<void> => {
    await this.ctx.post(
      `/storage/${id}/unbind_equipment/`,
      value,
    )
  }

  public unbindUserStorage = async (
    id: number,
    value: IUnbindUserStorageValue,
  ): Promise<void> => {
    await this.ctx.post(
      `/storage/${id}/unbind_user/`,
      value,
    )
  }

  public forcedSyncStorage = async (
    id: number,
  ): Promise<void> => {
    await this.ctx.post(
      `/storage/${id}/forced_sync/`,
      EMPTY_CONSTS.OBJ,
    )
  }

  public generateHistory = async ({
    id,
    ...values
  }: IDownloadReportParams): Promise<void> => {
    await this.ctx.post(
      `/storage/${id}/all_history/`,
      values,
    )
  }

  public getSettings = async (
    id: number,
  ): Promise<IStorageSettings> => {
    const res = await this.ctx.get<IStorageSettings>(
      `/storage/${id}/settings/`,
    )
    return res.data
  }

  public setSettings = async ({
    id,
    ...params
  }: {
    id: number
  } & IStorageSettings): Promise<IStorageSettings> => {
    const res = await this.ctx.patch<IStorageSettings>(
      `/storage/${id}/settings/`,
      params,
    )
    return res.data
  }

  public getEquipmentSelection = async (): Promise<{
    results: IStorageSerializedOption[]
  }> => {
    const res = await this.ctx.get<
      IStorageSettingsOption[]
    >('/storage/settings/equipment_selection_status/')
    return {
      results: serializeStorageOptions(res.data),
    }
  }

  public getUserSelection = async (): Promise<{
    results: IStorageSerializedOption[]
  }> => {
    const res = await this.ctx.get<
      IStorageSettingsOption[]
    >('/storage/settings/user_selection_status/')
    return {
      results: serializeStorageOptions(res.data),
    }
  }

  public unbindStorageByUserGroup = async (
    storageId: number,
    values: {
      group_id: number
    },
  ): Promise<void> => {
    await this.ctx.post(
      `/storage/${storageId}/unbind_group_user/`,
      values,
    )
  }
}

export default StorageApi
