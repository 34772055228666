import React from 'react'
import {
  EquipmentStatusIcon,
  EquipmentStatusNumber
} from '@/components/EquipmentStatusIcon'
import { Typography } from '@/views/typography'
import Icon from '@/components/Icon'
import { SidebarContainer } from '@/apps/RightSidebar/views/SidebarContainer'

import styles from './styles/index.module.scss'
import { TEquipStatus } from '@/api/interfaces'
import { isNull, isUndefined } from '@/utils'

/**
 *
 * Вьюха шапки для правого сайдбара оборудования
 * @view
 * @param [props.number] - номер оборудования
 * @param [props.title] - тайтл оборудования
 * @param [props.storageTitle] - тайтл шкафа
 * @param [props.status] - статус оборудования
 */
interface IEquipmentHeader {
  number: number | null
  title: string
  storageTitle?: string
  status: TEquipStatus
}

const EquipmentHeader: React.FC<IEquipmentHeader> = ({
  number,
  title,
  storageTitle,
  status
}) => {
  return (
    <div className={styles['equipment-header__root']}>
      <SidebarContainer>
        <div className={styles['equipment-header__grid']}>
          <EquipmentStatusIcon type={status} />
          <Typography
            size={32}
            weight={700}
            className={
              styles['equipment-header__grid_title']
            }
          >
            {title}
          </Typography>

          <div
            className={
              styles['equipment-header__subtitle_wrapper']
            }
          >
            {!isNull(number) && (
              <EquipmentStatusNumber
                value={String(number)}
                type={status}
                className={
                  styles[
                    'equipment-header__subtitle_number'
                  ]
                }
              />
            )}
            {!isUndefined(storageTitle) && (
              <>
                <Icon
                  size='small'
                  src='storage'
                  className={
                    styles[
                      'equipment-header__subtitle_icon'
                    ]
                  }
                />
                <Typography size={14} weight={400}>
                  {storageTitle}
                </Typography>
              </>
            )}
          </div>
        </div>
      </SidebarContainer>
    </div>
  )
}
export default EquipmentHeader
