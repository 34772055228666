import React, { FC, memo } from 'react'

import { NavLink } from 'react-router-dom'
import classNames from 'classnames'

import { IMenuItem } from '@/interfaces/Menus'

import { Typography } from '@/views/typography'

import styles from './styles/index.module.scss'
import { GxIcon } from '@garpix/garpix-web-components-react'

const MenuItem: FC<IMenuItem> = ({
  link,
  icon,
  title,
  is_current: active,
  target_blank: target
}) => {
  return (
    <NavLink
      target={target ? '_blank' : undefined}
      className={classNames(styles.linkblock, {
        [styles.active]: active
      })}
      to={link}
    >
      <GxIcon src={icon} className={styles.linkblock__icon} />
      <Typography
        className={styles.linkblock__title}
        weight={400}
        size={11}
      >
        {title}
      </Typography>
    </NavLink>
  )
}

export default memo(MenuItem)
