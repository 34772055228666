import {
  ISimpleStatuses,
  IStorageStatus,
} from '@/interfaces/consts'
import { IEntities } from './apps/Modals/ModalContent/interfaces'
import {
  IEquipmentAccessibilityStatuses,
  IEquipmentStatuses,
  IUserCreateValue,
  IUserGetValue,
} from './api/interfaces'
import { TEmptyTablePanel } from './components/EmptyTablePanel/interfaces'
import { ISystemSocketEvents } from '@/interfaces/Socket'
import { TIcon } from './components/Icon/interfaces'
import { PageModelType } from './api/interfaces/Page'

export const PATHS = {
  ALL: {
    path: '*',
  },
}

export const CHECKBOX_THEMES = {
  SELECT_ROW: 'select_row',
} as const

export const SPINNER_THEME = {
  MAIN_SIDEBAR: 'main_sidebar',
}

export const CREATE_EXCEL = {
  FILE: 'file',
  STORAGE_ID: 'storage_id',
  HID_FORMAT: 'hid_format',
}

export const EMPTY_CONSTS = {
  ARR: [],
  OBJ: {},
  TRUE: true,
  FALSE: false,
  NULL: null,
  UNDEFINED: undefined,
  STR: '',
  ZERO: 0,
  UNIT: 1,
  FUNC: () => {},
}

export const ADDITIONAL = {
  PAGE: { page: 1 },
}

export const SIMPLE_STATUSES: ISimpleStatuses = {
  LOADING: 'LOADING',
  ERROR: 'ERROR',
  SUCCESS: 'SUCCESS',
}

export const STORAGE_STATUS: IStorageStatus = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  NEW: 'NEW',
  START_SYNCHRONIZATION: 'START_SYNCHRONIZATION',
  SYNCHRONIZATION: 'SYNCHRONIZATION',
  WAIT: 'WAIT',
}

export const SOCKET_SIGNALS = {
  EQUIPMENTS: {
    CREATE_EQUIPMENTS: 'create_equipments',
    UPDATE_EQUIPMENT: 'update_equipment',
    UPDATE_CELL: 'update_cell',
  },
  USERS: {
    CREATE_USERS: 'create_users',
    UPDATE_USER: 'update_user',
  },
  STORAGES: {
    START_SYNC: 'start_synchronization',
    START_FORCE_SYNC: 'start_force_synchronization',

    SUCCESS_SYNC: 'success_synchronization',
    FORCE_SYNC_SUCCESS: 'force_success_synchronization',
    SETTINGS_SYNC_SUCCESS:
      'storage_success_synchronization',

    SYNC_ERROR: 'error_synchronization',
    INTEGRITY_ERROR_SYNC: 'integrity_error_synchronization',
  },
  INTEGRATION: {
    SUCCES_CONNECT: 'integration_success',
    ERROR_CONNECT: 'integration_error',
  },
  SYSTEM: 'system',
} as const

export const PRIVACY_SOCKET_EVENTS: ISystemSocketEvents = {
  UPLOAD_EXCEL_ERROR: 11,
  DOWNLOAD_REPORT: 4,
}

export const MOCKS_DATA = {}

export const DEFAULT_SETTINGS_PROJECT = {}

export const DEFAULT_SETTINGS_PROJECT_NAME = {}

export const TIMEOUT = {
  _2000: 2000,
  _1000: 1000,
}

export const STATUS_CODES = {
  _404: 404,
  _500: 500,
}

export const EQUIPMENT_PROBLEM_STATUS = {
  RESOLVED: 'resolved',
  UNRESOLVED: 'unresolved',
} as const

export const USER_ROLES = {}

export const DEFAULT_USER_EQUIPMENT_STATUS = 'absent'

export const SORT_BUTTON_FIELDS = {
  FULL_NAME: 'full_name',
} as const

export const DEFAULT_LINKS = {
  error_server: '/stranica-oshibki-servera',
  not_found: '/stranica404',
  root: '/',
  userPasswordRecovery: '/user-password-recovery',
  userAuth: '/user-auth',
  profile: '/profile',
  userList: '/user_list',
  equipmentList: '/equipment-list',
  groupUserPage: '/gruppa-polzovatelej',
}

export const BUTTON_COLORS = {
  TRANSPARENT: 'transparent',
} as const

export const KEYBOARD_EVENTS = {
  ENTER: 'Enter',
}

export const PAGE_TYPE: Record<string, PageModelType> = {
  userAuth: 'UserAuthorizationPage',
  storagePage: 'StoragePage',
}

export const SCROLL_TO = {
  SMOOTH_TOP: {
    top: 0,
    behavior: 'smooth' as ScrollBehavior,
  },
}

export const NOTIFICATION_TYPES = {
  DOWNLOAD_REPORT: 'DOWNLOAD_REPORT',
  NULL: null,
} as const

export const MODAL_TYPES = {
  EMPTY: 'EMPTY',
  STORAGE_TURN_OFF: 'STORAGE_TURN_OFF',
  EQUIPMENT_DELETE: 'EQUIPMENT_DELETE',
  USER_DELETE: 'USER_DELETE',
  LOG_OUT: 'LOG_OUT',
  EQUIPMENT_UNLINK_ERROR: 'EQUIPMENT_UNLINK_ERROR',
  FILE_UPLOAD_ERROR: 'FILE_UPLOAD_ERROR',
  STORAGE_EDIT: 'STORAGE_EDIT',
  USER_BINDING: 'USER_BINDING',
  USER_EDIT: 'USER_EDIT',
  EQUIPMENT_FILTER: 'EQUIPMENT_FILTER',
  USER_FILTER: 'USER_FILTER',
  STORAGE_USER_FILTER: 'STORAGE_USER_FILTER',
  STORAGE_FILTER: 'STORAGE_FILTER',
  CUSTOM: 'CUSTOM',
  USER_SIDEBAR: 'USER_SIDEBAR',
  UPLOAD_USER_EXCEL: 'UPLOAD_USER_EXCEL',
  UPLOAD_EQUIPMENT_EXCEL: 'UPLOAD_EQUIPMENT_EXCEL',
  USER_STORAGES_BIND: 'USER_STORAGES_BIND',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  RECOVERY_PASSWORD: 'RECOVERY_PASSWORD',
  NEW_PASSWORD: 'NEW_PASSWORD',
  SUCCES_EDIT_PASSWORD: 'SUCCES_EDIT_PASSWORD',
  USER_EQUIPMENTS_BIND: 'USER_EQUIPMENTS_BIND',
  EQUIPMENT_EDIT: 'EQUIPMENT_EDIT',
  EDIT_EMAIL: 'EDIT_EMAIL',
  CHECK_EMAIL_CODE: 'CHECK_EMAIL_CODE',
  SUCCESS_EDIT_EMAIL: 'SUCCESS_EDIT_EMAIL',
  SUCCESS_EDIT_PROFILE: 'SUCCESS_EDIT_PROFILE',
  EQUIPMENT_UNBIND: 'EQUIPMENT_UNBIND',
  SUCCESS_UPLOAD_EXCEL: 'SUCCESS_UPLOAD_EXCEL',
  EQUIPMENT_BIND: 'EQUIPMENT_BIND',
  USER_STORAGE_UNBIND: 'USER_STORAGE_UNBIND',
  WARNING_ERROR: 'WARNING_ERROR',
  DOWNLODA_HISTORY: 'DOWNLODA_HISTORY',
  STORAGE_SYNC: 'STORAGE_SYNC',
  PROBLEM_EDIT: 'PROBLEM_EDIT',
  USER_GROUP_EDIT: 'USER_GROUP_EDIT',
  USER_GROUP_BIND_STORAGES: 'USER_GROUP_BIND_STORAGES',
  USER_GROUP_BIND_EQUIPMENT: 'USER_GROUP_BIND_EQUIPMENT',
  SYNCHRONIZATION: 'SYNCHRONIZATION',
  USER_GROUP_BIND_USER: 'USER_GROUP_BIND_USER',
  USER_GROUP_DELETE: 'USER_GROUP_DELETE',
}

export const MODAL_CUSTOM_CONTENT = {
  DOWNLOAD_FILE_ERROR: {
    icon: 'file',
    title: {
      id: 'modal.title.download_file_error',
      defaultMessage: 'Ошибка скачивания файла',
    },
    description: {
      id: 'modal.description.download_file_error',
      defaultMessage:
        'Произошла ошибка скачивания файла. Попробуйте снова.',
    },
    accept: {
      id: 'common.continue',
      defaultMessage: 'Продолжить',
    },
  },
  SUCCESS_UPLOAD_EXCEL: {
    icon: 'file',
    title: {
      id: 'modal.title.upload_file_success',
      defaultMessage: 'Успешная загузка файла',
    },
    description: {
      id: 'modal.description.upload_file_description',
      defaultMessage: 'Ваш файл был успешно загружен',
    },
    accept: {
      id: 'common.continue',
      defaultMessage: 'Продолжить',
    },
  },
  ERROR: {
    icon: 'error',
    title: {
      id: 'didntWork',
      defaultMessage: 'Не получилось!',
    },
    description: {
      id: 'modal.content.warning.description',
      defaultMessage: 'Что-то пошло не так...',
    },
    accept: {
      id: 'common.continue',
      defaultMessage: 'Продолжить',
    },
  },
  DATE_REPORT_ERROR: {
    icon: 'error',
    title: {
      id: 'modal.date_report_error.title',
      defaultMessage: 'Ошибка формирования отчета',
    },
    description: {
      id: 'modal.date_report_error.description',
      defaultMessage:
        'Пожалуйста, укажите период формирования отчета',
    },
    accept: {
      id: 'common.continue',
      defaultMessage: 'Продолжить',
    },
  },
  SUCCES_SETTING_SYNC: {
    icon: 'successStatus',
    accept: {
      id: 'common.continue',
      defaultMessage: 'Продолжить',
    },
  },
  ERROR_SYNC: {
    icon: 'error',
    accept: {
      id: 'common.continue',
      defaultMessage: 'Продолжить',
    },
  },
  CONFIRM_USER_UNLOCKING: {
    icon: 'unlock',
    title: {
      id: 'modal.confirm_user_unlock.title',
      defaultMessage:
        'Вы точно хотите разблокировать всех пользователей',
    },
    decline: {
      id: 'common.cancel',
      defaultMessage: 'Отмена',
    },
    accept: {
      id: 'common.continue',
      defaultMessage: 'Продолжить',
    },
  },
} as const

export const THEME_MODAL = {
  DEFAULT: 'default',
  FILTER: 'filter',
  RIGHT_SIDEBAR: 'right-sidebar',
  NOTIFICATIONS: 'notifications',
  LARGE: 'large',
}

export const NOTIFICATION_STATUS = {
  SUCCESS: 'success',
  ERROR: 'error',
}

export const DEFAULT_PAGES = {
  PAGE_403: 'Page403',
  404: '404',
  500: '500',
}

export const BADGE_THEME = {
  DEFAULT: 'default',
  ICON: 'icon',
}

export const DAY_MILLISECONDS = 1000 * 60 * 60 * 24

export const GX_CREATE_USER_SESSION =
  'gx_create_user_session'
export const GX_ACCESS_TOKEN = 'gx_access_token'
export const GX_ACCESS_TOKEN_EXPIRES =
  'gx_access_token_expires'

export const COLORS = {
  SELECT_ACTIVE: '#ffa5006e',
  TRANSPARENT: 'transparent',
  SELECT_CHOSEN: '#ffa500',
  WHITE: '#fff',
}

export const DEFAULT_CURRENT_PAGE = 1
export const DEFAULT_TOTAL_PAGES = 0
export const DEFAULT_PAGE_SIZE = 9
export const MEDIUM_PAGE_SIZE = 7

export const PAGE_SIZES = {
  extraSmall: 6,
  small: 7,
  medium: 9,
  large: 12,
  extra: 15,
}
export const PAGE_SIZE_10 = 10
export const DEFAULT_SELECT_PAGE_SIZE = 30
export const DEFAULT_PAGE_LAZY_SIZE = 15
export const DEFAULT_CELL_PAGE_SIZE = 25

export const EMPTY_STORAGE = {
  serial_number: '', // серийный номер шкафа (обязательное поле)
  storage_secret_key: '', // секретный ключ шкафа (обязательное поле)
  storage_url: '', // ссылка шкафа (обязательное поле)
  title: '', // название шкафа (обязательное поле)
  description: '',
}

export const DEFAULT_USER_ROLE = 'workman'
export const DEFAULT_USER_ACCOUNT_TYPE = 'user'

export const EMPTY_USER: IUserCreateValue = {
  account_type: DEFAULT_USER_ACCOUNT_TYPE,
  user_role: DEFAULT_USER_ROLE,
  last_name: '',
  first_name: '',
  patronymic: '',
  email: '',
  card_hid_number: '',
}

export const INTL_DEFAULT_MESSAGE = {
  REQUIRED: {
    id: 'error.requiredField',
    defaultMessage: 'Обязательное поле',
  },
  UNCORRECT_EMAIL: {
    id: 'validation.uncorrect_email',
    defaultMessage: 'Некорректный email',
  },
  UUMATCH_PASSWORDS: {
    id: 'validation.password_match',
    defaultMessage: 'Пароли не совпадают',
  },
  MAX: {
    id: 'maxLength',
    defaultMessage:
      'Превышено максимальное количество допустимых символов',
  },
  UNCORRECT_URL: {
    id: 'validation.uncorrect_url',
    defaultMessage: 'Введите правильный URL',
  },
  MAX_START_DATE: {
    id: 'error.startMoreEnd',
    defaultMessage:
      'Начальная дата не может превышать конечную',
  },
  MAX_DATE: {
    id: 'error.dateLaterDateThanToday',
    defaultMessage: 'Дата - более поздняя, чем сегодня',
  },
  COMBINATION_NOT_AVAILABLE: {
    id: 'error.combinationNotAvailable',
    defaultMessage: 'Данная комбинация настроек недоступна',
  },
}

export const STORAGE_EDIT_SECKRET_KEY_NAME =
  'storage_secret_key'

export const STORAGE_EDIT_TOP_FIELDS = [
  {
    id: 0,
    name: 'serial_number',
    type: 'text',
    label: {
      id: 'storage.serialNumber',
      defaultMessage: 'Название',
    },
  },
  {
    id: 1,
    name: 'storage_secret_key',
    type: 'password',
    label: 'Secret key',
  },
]

export const STORAGE_EDIT_CORE_FIELDS = [
  {
    id: 0,
    name: 'storage_url',
    label: {
      id: 'storage.storageUrl',
      defaultMessage: 'URL шкафа',
    },
    required: true,
  },
  {
    id: 1,
    name: 'title',
    label: {
      id: 'equipment.title',
      defaultMessage: 'Название',
    },
    required: true,
  },
]

export const USER_EDIT_CORE_FIELDS = [
  {
    id: 0,
    name: 'last_name',
    label: {
      id: 'user.last_name',
      defaultMessage: 'Фамилия',
    },
    required: true,
  },
  {
    id: 1,
    name: 'first_name',
    label: {
      id: 'user.first_name',
      defaultMessage: 'Имя',
    },
    required: true,
  },
  {
    id: 2,
    name: 'patronymic',
    label: {
      id: 'user.patronymic',
      defaultMessage: 'Отчество',
    },
    required: false,
  },
]

export const USER_EDIT_RADIO_FIELDS = [
  {
    name: 'account_type',
    value: 'user',
    id: 'user.account_type.user',
    defaultMessage: 'Пользователь',
  },
  {
    name: 'account_type',
    value: 'employee',
    id: 'user.account_type.employee',
    defaultMessage: 'Имя',
  },
]

export const USER_EDIT_SELECT_FIELDS = [
  {
    label: 'Рабочий',
    value: 'workman',
  },
  {
    label: 'Сервисный менеджер',
    value: 'serviceman',
  },
  {
    label: 'Инженер',
    value: 'engineer',
  },
]

export const USER_ROLE_FIELDS = [
  {
    label: 'Все',
    value: '',
  },
  ...USER_EDIT_SELECT_FIELDS,
]

export const USER_ACCOUNT_TYPE_FIELDS = [
  {
    label: 'Все',
    value: '',
  },
  {
    label: 'Пользователь',
    value: 'user',
  },
  {
    label: 'Сотрудник',
    value: 'employee',
  },
]

export const USER_EQUIPMENT_STATUS_FIELDS = [
  {
    label: 'Все',
    value: '',
  },
  {
    label: 'На руках',
    value: 'on_hands',
  },
  {
    label: 'Отсутствует',
    value: 'absent',
  },
]

export const STORAGE_STATUS_FIELDS = [
  {
    label: 'Все',
    value: '',
  },
  {
    label: 'Ожидание синхронизации',
    value: 'WAIT',
  },
  {
    label: 'Начато подключение',
    value: 'START_SYNCHRONIZATION',
  },
  {
    label: 'Синхронизация',
    value: 'SYNCHRONIZATION',
  },
  {
    label: 'Ошибка синхронизации',
    value: 'ERROR',
  },
  {
    label: 'Синхронизация успешна',
    value: 'SUCCESS',
  },
]

export const EQUIPMENT_STATUS_FIELDS = [
  {
    label: 'Все',
    value: '',
  },
  {
    label: 'Новое',
    value: 'new',
  },
  {
    label: 'Исправно',
    value: 'available',
  },
  {
    label: 'В работе',
    value: 'at_work',
  },
  {
    label: 'Неисправно',
    value: 'not_working',
  },
  {
    label: 'Изъято',
    value: 'withdrawn',
  },
  {
    label: 'В ремонте',
    value: 'under_repair',
  },
]

export const EQUIPMENT_ACCESSIBILITY_STATUS: IEquipmentAccessibilityStatuses =
  {
    USER_NEW: 'user_new',
    USER_AVAILABLE: 'user_available',
    USER_AT_WORK: 'user_at_work',
    USER_NOT_WORKING: 'user_not_working',
    USER_WITHDRAWN: 'user_withdrawn',
    USER_UNDER_REPAIR: 'user_under_repair',
    EQUIPMENT_ON_HANDS: 'equipment_on_hands',
    EQUIPMENT_ABSENT: 'equipment_absent',
  }
export const EQUIPMENTS_STATUS: IEquipmentStatuses = {
  NEW: 'new',
  AVAILABLE: 'available',
  AT_WORK: 'at_work',
  NOT_WORKING: 'not_working',
  WITHDRAWN: 'withdrawn',
  UNDER_REPAIR: 'under_repair',
  TEMPORARILY_RENTED: 'temporarily_rented',
}

export const EMPTY_FILTER_PARAMS = {
  storage_id: undefined,
  user_role: undefined,
  equipment_status: undefined,
  storage_status: undefined,
  equipment_type: undefined,
  status: undefined,
  account: undefined,
}

export const FILTER_PARAMS_KEYS_ARRAY = [
  'storage_id',
  'user_role',
  'equipment_status',
  'storage_status',
  'equipment_type',
  'status',
  'account',
]

export const DEFAULT_USER_INIT_VALUE: IUserGetValue = {
  id: 0,
  account_type: 'user',
  user_role: 'workman',
  last_name: '',
  first_name: '',
  email: '',
  card_hid_number: '',
  full_name: '',
  patronymic: '',
  updated_at: '',
}

export const EMPTY_STATUS = ''
export const EMPTY_ROLE = ''
export const DEFAULT_SELECT_VALUE = ''

export const MAX_SEARCH_LENGTH = 256
export const MAX_LENGTH = {
  LABEL: 50,
}
export const MIN_LENGTH = {
  ERROR: 1,
}
export const TABLE_PAGE: IEntities = {
  EQUIPMENT: 'equipment',
  STORAGE: 'storage',
  USER: 'user',
  CELL: 'cell',
  STORAGE_USER: 'storageUser',
  PROBLEM: 'problem',
}

export const DEFAULT_TABLE_PAGE = TABLE_PAGE.STORAGE

export const NETWORK_ERROR = 'Network Error'

export const DEFAULT_ERROR_ARRAY: string[] = []

export const FILTER_KEY = {
  STORAGE_ID: 'storage_id',
  USER_ROLE: 'user_role',
  EQUIPMENT_STATUS: 'equipment_status',
  STORAGE_STATUS: 'storage_status',
  EQUIPMENT_TYPE: 'equipment_type',
  STATUS: 'status',
  PAGE: 'page',
  STORAGE_EQUIPMENT_TYPE: 'equipments__equipment_type',
  CELL_STATUS: 'cell_status',
  ACCOUNT_TYPE: 'account',
  CREATED_AT: 'created_at',
}

export const STORAGE_CELL_STATUS = {
  FREE: 'FREE',
  OCCUPIED_DEFECTIVE: 'OCCUPIED_DEFECTIVE',
  OCCUPIED_SERVICEABLE: 'OCCUPIED_SERVICEABLE',
  FREE_REVERSED: 'FREE_REVERSED',
  OCCUPIED_TEMPORARILY_RENTER:
    'OCCUPIED_TEMPORARILY_RENTER',
} as const

export const SCROLL_END_PERCENT = 85
export const MAX_PERCENT = 100

export const TABLE_WRAPPER_TYPE: {
  [key: string]: TEmptyTablePanel
} = {
  ...TABLE_PAGE,
  EQUIPMENT_USER_TAB: 'equipment_user_tab',
  USER_EQUIPMENT_TAB: 'user_equipment_tab',
  STORAGE_USER_TAB: 'storage_user_tab',
  USER_GROUP_EQUIPMENT_TAB: 'user_group_equipment_tab',
  USER_GROUP_USER_TAB: 'user_group_user_tab',
  USER_GROUP_STORAGE_TAB: 'user_group_storage_tab',
}

export const ANIMATION_TIME = {
  MEDIUM: 800,
}

export const INITIAL_AUTH_VALUES = {
  username: EMPTY_CONSTS.STR,
  password: EMPTY_CONSTS.STR,
}

export const EQUIPMENT_STATUS = {
  ABSENT: 'absent',
  ON_HANDS: 'on_hands',
} as const

export const EQUIPMENT_STATUS_ICON = {
  ABSENT: 'equipmentMiniOff',
  ON_HANDS: 'equipmentMiniOn',
}

export const MEDIA_QUERY = {
  LARGE: '(min-width: 568px)',
  MEDIA_900: '(max-width: 900px)',
  MEDIA_700: '(max-width: 700px)',
  MEDIA_1200: '(max-width: 1200px)',
}

export const EQUIPMENT_KEY = {
  ID: 'id',
  TITLE: 'title',
  SERIAL_NUMBER: 'serial_number',
  INVENTORY_NUMBER: 'inventory_number',
  UPDATED_AT: 'updated_at',
  STATUS: 'status',
  STORAGE: 'storage',
}

export const EQUIPMENT_FILTER_KEY = {
  ID: 'id',
  TITLE: 'title',
  SERIAL_NUMBER: 'serial_number',
  INVENTORY_NUMBER: 'inventory_number',
  UPDATED_AT: 'updated_at',
  STATUS: 'status',
  STORAGE: 'storage__title',
}

export const EMPTY_SEARCH_TYPE = {
  default: 'default',
}

export const PAGE_MODELS = {
  Page403: 'Page403',
  Page404: 'Page404',
  PageNotFound: 'PageNotFound',
  PageErrorServer: 'PageErrorServer',
  Page: 'Page',
  UserAuthorizationPage: 'UserAuthorizationPage',
  UserPasswordRecoveryPage: 'UserPasswordRecoveryPage',
  UserProfilePage: 'UserProfilePage',
  EquipmentListPage: 'EquipmentListPage',
  UserListPage: 'UserListPage',
  StorageListPage: 'StorageListPage',
  StoragePage: 'StoragePage',
}

export const CONFIRM_CODE_PLACEHOLDER = 'XXXXXX'
export const CONFIRM_CODE_MAX_LENGTH = 6

export const INPUT_ICON_WIDTH = 35

export const DEFAULT_STORAGE_STATUS = 'NEW'

export const DATE_MASKS = {
  DEFAULT: 'dd.MM.yyyy',
  CALENDAR_VALUE: 'yyyy-MM-dd',
}

export const INITIAL_DATE_LENGTH = 3

export const TABLE_WRAPPER_ICON_SRC: {
  [key: string]: TIcon
} = {
  USER: 'user',
  EQUIPMENT: 'equipment',
  STORAGE: 'storage',
  PROBLEM: 'problem',
}

export const PROBLEM_TYPE_SELECT_FIELDS = [
  {
    label: 'Требует описания',
    value: 'required_description',
  },
  {
    label: 'Не требует описания',
    value: 'not_required_description',
  },
]

export const PROBLEM_KEY = {
  ID: 'id',
  TITLE: 'title',
  TYPE: 'type',
  EQUIPMENT_TYPES: 'equipment_types',
}

export const DATA_INPUT_THEME = {
  DEFAULT: 'datepicker__default',
  FULL: 'datepicker__full',
} as const

export const ACCOUNT_TYPE = {
  USER: 'user',
  EMPLOYEE: 'employee',
} as const

export const USER_SHOWING_PANELS = {
  ASIDE_LEFT: 'asideLeft',
  SECTION_RIGHT: 'sectionRight',
}

export const HTTP_RESPONSE_TYPES = {
  INFORMATIONAL: 1,
  SUCCESS: 2,
  REDIRECT: 3,
  CLIENT_ERROR: 4,
  SERVER_ERROR: 5,
  UNCORRECT: -1,
} as const
