import React from 'react'

import { Typography } from '@/views/typography'
import styles from '../styles/index.module.scss'
import { Button } from '@/views/button'
import { useIntl } from 'react-intl'
import Icon from '@/components/Icon'

interface IProps {
  handleClose: () => void
}

const Header: React.FC<IProps> = ({ handleClose }) => {
  const intl = useIntl()
  return (
    <div className={styles.settings__head}>
      <Typography size={32} weight={700}>
        {intl.formatMessage({
          id: 'storageSettings',
          defaultMessage: 'Настройки шкафа'
        })}
      </Typography>
      <Button
        variant='text'
        color='gray'
        onClick={handleClose}
        className={styles.settings__head_close}
      >
        <Icon src='close' />
      </Button>
    </div>
  )
}

export default Header
