import React, { useState } from 'react'
import { Field } from 'formik'

import { Input } from '@/views/input'
import { Button } from '@/views/button'
import FormCheckbox from '../../../components/FormCheckbox'

import {
  DEFAULT_INTEGRATION_FIELDS,
  SCUD_SETTINGS_CHECKBOXS,
} from '@/apps/Integration/const'

import { useStores } from '@/hooks'
import { HTTP_RESPONSE_TYPES, MODAL_TYPES } from '@/const'

import {
  convertErrorToString,
  getHttpRequestStatusType,
  isUndefined,
} from '@/utils'

import style from '../style/index.module.scss'
import useTranslate, {
  TMessage,
} from '@/hooks/useTranslate'
/**
 * @view
 * * поля настроек интеграции СКУД
 * * чекбокс синхронизации пользователей
 * * чекбокс рассылки уведомлений на почту
 * * поле с почтой для получения рассылки
 */
const IntegrationSettings = (): React.ReactElement => {
  const { intl } = useTranslate()
  const { modalStore, api } = useStores()
  const [isDisabled, setIsDisabled] = useState(false)
  const handleCloseErrorModal = (
    text: TMessage = {
      id: 'modal.content.server_error.sync_skud',
      defaultMessage:
        'Сихронизация не запущена, неизвестная ошибка',
    },
  ): void => {
    modalStore.open(MODAL_TYPES.SYNCHRONIZATION, {
      text,
    })
  }
  const handleForceSync = (): void => {
    setIsDisabled(true)
    api.integration
      .forcedSynchronization()
      .then(() =>
        handleCloseErrorModal({
          id: 'modal.content.success.sync_skud',
          defaultMessage:
            'Запущен процесс синхронизации пользователей со СКУД',
        }),
      )
      .catch((error) => {
        const statusType = getHttpRequestStatusType(
          error.response.status,
        )
        if (
          statusType === HTTP_RESPONSE_TYPES.SERVER_ERROR
        ) {
          handleCloseErrorModal()
        }
        if (
          statusType === HTTP_RESPONSE_TYPES.CLIENT_ERROR
        ) {
          if (!isUndefined(error?.response?.data)) {
            const formattedError =
              convertErrorToString(error)
            handleCloseErrorModal(formattedError)
          } else {
            handleCloseErrorModal()
          }
        }
      })
      .finally(() => {
        setIsDisabled(false)
      })
  }
  return (
    <div className={style.sync}>
      <div className={style.sync__top}>
        <div className={style.sync__checks}>
          {SCUD_SETTINGS_CHECKBOXS.map(
            ({ id, label, name }) => (
              <FormCheckbox
                key={id}
                nameField={name}
                label={label}
                className={style['checkbox-lable']}
              />
            ),
          )}
        </div>
        <Button
          color="orange"
          variant="text"
          className={style.sync__button}
          disabled={isDisabled}
          onClick={handleForceSync}
        >
          {intl.formatMessage({
            id: 'synchronize',
            defaultMessage: 'Синхронизовать',
          })}
        </Button>
      </div>

      <Field name={DEFAULT_INTEGRATION_FIELDS.email}>
        {({ field, meta, form }) => (
          <Input
            {...field}
            outerStyles={style.field}
            label={intl.formatMessage({
              id: 'emailNotify',
              defaultMessage: 'Почта для уведомлений',
            })}
            disabled={!form.values.is_email_alerts}
            error={
              Boolean(meta.error) && Boolean(meta.touched)
            }
            errorMessage={meta.error}
          />
        )}
      </Field>
    </div>
  )
}

export default IntegrationSettings
