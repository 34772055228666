import React, {
  MutableRefObject,
  useEffect,
  useRef,
} from 'react'
import UsersPanelView from '@/apps/RightSidebar/views/EquipmentSideBarView/UsersPanel'
import {
  IEquipment,
  ITiedEquipmentUsersListParams,
  TUser,
} from '@/api/interfaces'
import { useLazyFetch, useStores } from '@/hooks'
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_PAGE_LAZY_SIZE,
  MODAL_TYPES,
  SCROLL_TO,
} from '@/const'
import { USER_STORAGE_UNBIND_VARIANT } from '@/apps/Modals/const'
import { isUndefined } from '@/utils'

interface IUsersPanelProps {
  id: number
  handleEdit: () => void
  equipmentData: IEquipment
  refetchRef: MutableRefObject<() => void>
}

/**
 * Компонет панели пользователей деталки оборудования
 * @param id              - id оборудования
 * @function handleEdit   - редактирование оборудования
 * @param equipmentData   - оборудование
 * @param refetchRef      - ref на функцию обновления refetch
 * @constructor
 */

const UsersPanel: React.FC<IUsersPanelProps> = ({
  id,
  handleEdit,
  equipmentData,
  refetchRef,
}) => {
  /**
   * Реф на скролящийся список
   */
  const scrollableListRef = useRef<HTMLDivElement>(null)

  const { api, modalStore } = useStores()
  const {
    params,
    lazyLoadedData: data,
    isLoading: isFetching,
    error,
    tableScroll: handleScroll,
    handleParams,
    refetch,
    isScrollEnd,
  } = useLazyFetch(
    scrollableListRef,
    api.equipment.getUsers,
    {
      id,
      page_size: DEFAULT_PAGE_LAZY_SIZE,
    },
  )

  /**
   * Фетч по параметрам для фильтра и поиска
   * @param params
   */
  const handleFetch = (
    params: ITiedEquipmentUsersListParams,
  ): void => {
    if (scrollableListRef.current != null) {
      scrollableListRef.current.scrollTo(
        SCROLL_TO.SMOOTH_TOP,
      )
    }
    const newParams = {
      name: params.search,
      page: DEFAULT_CURRENT_PAGE,
    }
    return handleParams(
      newParams as ITiedEquipmentUsersListParams,
    )
  }

  /**
   * Отвязка оборудования от пользователя
   * @func handleUnbindEquipment
   * @param userID
   */
  const handleUnbindEquipment =
    (userID: number) => async () =>
      await api.equipment.unbindUserEquipment(id, {
        user_id: userID,
      })

  /**
   * метод для открытия модального окна для
   * отвязывания оборудования от пользователя
   */
  const handleUnbind = (userData: TUser): void => {
    modalStore.open(MODAL_TYPES.USER_STORAGE_UNBIND, {
      handleUnbindMethod: handleUnbindEquipment(
        userData.id,
      ),
      userData,
      refetch,
      type: USER_STORAGE_UNBIND_VARIANT.USER_UNBIND,
    })
  }

  const isLoading = isFetching && isUndefined(data)

  const props = {
    listRef: scrollableListRef,
    data,
    isLoading,
    error,
    handleScroll,
    handleFetch,
    handleUnbind,
    handleEdit,
    params,
    equipmentData,
    isScrollEnd,
  } as any

  /**
   * @info useEffect
   * следит и добавляет refetch (ф-ция обновления) в refetchRef
   */
  useEffect(() => {
    refetchRef.current = refetch
  }, [params])

  return <UsersPanelView {...props} />
}

export default UsersPanel
