import React, { MutableRefObject, useState } from 'react'
import { defineMessage, useIntl } from 'react-intl'

import {
  TabPanel,
  Tab,
  TabGroup
} from '@/components/TabGroup'
import UsersPanel from '../../../components/EquipmentSidebar/UsersPanel'
import ProblemsPanel from '@/apps/RightSidebar/components/EquipmentSidebar/ProblemsPanel'
import HistoryPanel from '@/apps/RightSidebar/components/EquipmentSidebar/HistoryPanel'

import { IEquipment } from '@/api/interfaces'

import { EMPTY_CONSTS } from '@/const'
import { EQUIPMENT_TAB } from '@/apps/RightSidebar/const'

import styles from './styles/index.module.scss'

const tabs = [
  {
    panel: EQUIPMENT_TAB.USERS,
    title: defineMessage({
      id: 'users',
      defaultMessage: 'Пользователи'
    }),
    activeStatus: EQUIPMENT_TAB.USERS
  },
  {
    panel: EQUIPMENT_TAB.PROBLEMS,
    title: defineMessage({
      id: 'problems',
      defaultMessage: 'Проблемы'
    }),
    activeStatus: EQUIPMENT_TAB.PROBLEMS
  },
  {
    panel: EQUIPMENT_TAB.HISTORY,
    title: defineMessage({
      id: 'history',
      defaultMessage: 'История'
    }),
    activeStatus: EQUIPMENT_TAB.HISTORY
  }
]

const panels = [
  {
    content: (props) => <UsersPanel {...props} />,
    name: EQUIPMENT_TAB.USERS,
    activeStatus: EQUIPMENT_TAB.USERS
  },
  {
    content: (props) => <ProblemsPanel {...props} />,
    name: EQUIPMENT_TAB.PROBLEMS,
    activeStatus: EQUIPMENT_TAB.PROBLEMS
  },
  {
    content: (props) => <HistoryPanel {...props} />,
    name: EQUIPMENT_TAB.HISTORY,
    activeStatus: EQUIPMENT_TAB.HISTORY
  }
]

interface IEquipmentTabsProps {
  equipmentID: number
  handleEdit: () => void
  equipmentData: IEquipment
  refetchRef: MutableRefObject<() => void>
}

/**
 * * вью табов шкафов (панель переключения
 * между Пользователи, Проблемы и История)
 * @view
 * @param equipmentID   - id оборудования
 * @function handleEdit - редактирование оборудования
 * @param equipmentData - оборудование
 * @param refetchRef    - ref на функцию обновления refetch
 */
const EquipmentTabs: React.FC<IEquipmentTabsProps> = ({
  equipmentID,
  handleEdit,
  equipmentData,
  refetchRef
}) => {
  const intl = useIntl()
  const [activeTab, setActiveTab] = useState<
  (typeof EQUIPMENT_TAB)[keyof typeof EQUIPMENT_TAB]
  >(EQUIPMENT_TAB.USERS)

  return (
    <TabGroup className={styles['tab-group']}>
      {tabs.map((tab) => (
        <Tab
          key={tab.panel}
          slot='nav'
          className={styles['tab-group__tab']}
          panel={tab.panel}
          active={activeTab === tab.activeStatus}
          onClick={() => setActiveTab(tab.activeStatus)}
        >
          {intl.formatMessage(tab.title)}
        </Tab>
      ))}
      {panels.map((panel) => (
        <TabPanel
          key={panel.name}
          name={panel.name}
          className={styles['tab-group__panel']}
          active={activeTab === panel.activeStatus}
        >
          {activeTab === panel.activeStatus
            ? panel.content({
              id: equipmentID,
              handleEdit,
              equipmentData,
              refetchRef
            })
            : EMPTY_CONSTS.NULL}
        </TabPanel>
      ))}
    </TabGroup>
  )
}

export default EquipmentTabs
