import React from 'react'
import { NavLink } from 'react-router-dom'
import { useSiteConfig } from '@/hooks'
import { LogoIcon } from '@/assets/icon'
import { DEFAULT_LINKS } from '@/const'
import style from './styles/index.module.scss'

/**
 * ** Component  -  компонент логотипа для меню
 * ? подойдет только для этой апп
 */
const Logo: React.FC = () => {
  const config = useSiteConfig()
  const linkToRoot =
    config?.links?.root ?? DEFAULT_LINKS.root

  return (
    <NavLink to={linkToRoot}>
      <div className={style.container}>
        <img
          src={LogoIcon}
          width='50px'
          height='50px'
          alt='logo'
        />
      </div>
    </NavLink>
  )
}

export default Logo
