import React from 'react'
import { Field, useField } from 'formik'
import { Input } from '@/views/input'
import { useTranslate } from '@/hooks'
import { BOTTOM_FIELDS, DEFAULT_INTEGRATION_FIELDS } from '@/apps/Integration/const'

import styles from '../style/index.module.scss'
/**
 * @view
 * * нижние поля интеграции скуда
 * * логин и пароль
 */
const BottomFields = (): React.ReactElement => {
  const { translate } = useTranslate()
  const [{ value }] = useField(DEFAULT_INTEGRATION_FIELDS.is_need_auth)
  return (
    <div className={styles.animation} data-show={value}>
      <div className={styles.wrap}>
        {BOTTOM_FIELDS.map(({ id, name, label, type }) => (
          <Field name={name} key={id}>
            {({ field, meta }) => (
              <Input
                {...field}
                outerStyles={styles.field}
                type={type}
                error={
                  Boolean(meta.error) &&
                  Boolean(meta.touched)
                }
                errorMessage={meta.error}
                label={translate(label)}
              />
            )}
          </Field>
        ))}
      </div>
    </div>
  )
}

export default BottomFields
