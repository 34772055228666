import React from 'react'

import cn from 'classnames'
import { IntlShape } from 'react-intl'

import { Typography } from '@/views/typography'
import { Spinner } from '@/views/spinner'

import style from './style/index.module.scss'

interface IProp {
  intl: IntlShape
}
/**
 * @view
 * * вью отражающий текущий статус шкафа на стр его деталки
 */
export const Synchronization = ({
  intl
}: IProp): React.ReactElement => {
  return (
    <>
      <Spinner className={cn(style.status__icon, style.status__icon_sync)} size='medium' />
      <Typography className={style.status__title} size={32} weight={700}>
        {intl.formatMessage({
          id: 'connected.sync',
          defaultMessage: 'Начато подключение...'
        })}
      </Typography>
    </>
  )
}
