import React, { FC } from 'react'
import { Field } from 'formik'
import { GxCheckboxCustomEvent } from '@garpix/garpix-web-components'

import Checkbox from '@/components/Checkbox'

import { TMessage } from '@/hooks/useTranslate'
import { useTranslate } from '@/hooks'

interface ICheckbox {
  nameField: string
  label: TMessage
  className?: string
}

/**
 * @component FormCheckbox
 * * компонент чекбокса который используется конктено в интеграциях
 * * он работает внутри формы поэтому обернут в field
 *
 * @param nameField                       имя поля формы
 * @param label                           подпись чекбокса
 * @param className                       дополнительные стили
 */
const FormCheckbox: FC<ICheckbox> = ({
  nameField,
  label,
  className = '',
}) => {
  const { translate } = useTranslate()
  return (
    <Field name={nameField}>
      {({ field, form }) => {
        const { name, onBlur, value } = field
        const handleChange = (
          e: GxCheckboxCustomEvent<HTMLGxCheckboxElement>,
        ): void => {
          form.setFieldValue(field.name, e.target.checked)
        }
        return (
          <Checkbox
            name={name}
            onGx-change={handleChange}
            onGx-blur={onBlur}
            checked={value}
          >
            <span className={className}>
              {translate(label)}
            </span>
          </Checkbox>
        )
      }}
    </Field>
  )
}

export default FormCheckbox
