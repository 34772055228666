import React, { ReducerWithoutAction, useEffect, useReducer } from 'react'
import { AbstractSocket, useInit, useSocketCollectCallbacks } from '@/services'
import { runtimeConfig } from '@/config'

import { observer } from 'mobx-react'
import { useStores } from '@/hooks'
import { SOCKET_SIGNALS } from '@/const'
import { collectApiUrl, collectDomain } from '@/utils'

const Socket: React.FC = observer(() => {
  const { 
    integrationEventsSocket, 
    StorageEventsObserver, 
    ExelEventsObserver,
    EquipmentEventsObserver,
    UserEventsObserver
  } =
    useStores();
  const API_WS_URL: string = collectApiUrl({
    protocol: runtimeConfig.REACT_APP_WS_PROTOCOL,
    domain: collectDomain(
      runtimeConfig.REACT_APP_IS_LOCAL_ONLY,
      runtimeConfig.REACT_APP_WS_DOMAIN
    ),
    path: runtimeConfig.REACT_APP_API_WS_GENERAL_URL
  })
  /**
   * для реинициализации подключения после дисконекта
   */
  const [count, setCount] = useReducer<ReducerWithoutAction<number>>(
    (prev) => ++prev,
    0
  )
  let absSocket: AbstractSocket | undefined // ссылка на инстанс сокета
  let timer: NodeJS.Timeout

  // [SOCKET EVENTS]
  const open = (): void => {}
  const connect = (): void => {}
  const disconnect = (): void => {
    setCount()
  }
  const error = (): void => {}

  // [END SOCKET EVENTS]

  const collectDefaultSocketEvent = {
    // [DEFAULT]
    open,
    connect,
    disconnect,
    error,
    [SOCKET_SIGNALS.EQUIPMENTS.CREATE_EQUIPMENTS]: ExelEventsObserver.onCreateExel,
    [SOCKET_SIGNALS.USERS.CREATE_USERS]: ExelEventsObserver.onCreateExel,

    [SOCKET_SIGNALS.STORAGES.SUCCESS_SYNC]: StorageEventsObserver.onSuccesSync,
    [SOCKET_SIGNALS.STORAGES.SETTINGS_SYNC_SUCCESS]:
      StorageEventsObserver.onSuccessSettingsSync,
    [SOCKET_SIGNALS.STORAGES.SYNC_ERROR]: StorageEventsObserver.onErrorSync,
    [SOCKET_SIGNALS.STORAGES.START_SYNC]: StorageEventsObserver.onStartSync,
    [SOCKET_SIGNALS.STORAGES.START_FORCE_SYNC]:
      StorageEventsObserver.onStartForceSync,
    [SOCKET_SIGNALS.STORAGES.FORCE_SYNC_SUCCESS]:
      StorageEventsObserver.onSuccesForceSync,
    [SOCKET_SIGNALS.STORAGES.INTEGRITY_ERROR_SYNC]:
      StorageEventsObserver.onIntegrityErrorSynchronization,

    [SOCKET_SIGNALS.EQUIPMENTS.UPDATE_CELL]:
      EquipmentEventsObserver.onUpdateCell,
    [SOCKET_SIGNALS.EQUIPMENTS.UPDATE_EQUIPMENT]:
      EquipmentEventsObserver.onUpdateEquipment,

    [SOCKET_SIGNALS.USERS.UPDATE_USER]: 
      UserEventsObserver.onUpdateUser,

    [SOCKET_SIGNALS.INTEGRATION.SUCCES_CONNECT]:
      integrationEventsSocket.integrationEventsSubscription,
    [SOCKET_SIGNALS.INTEGRATION.ERROR_CONNECT]:
      integrationEventsSocket.integrationEventsSubscription
  }

  // /**Для имитации работы соетов локально */
  // useEffect(() => {
  //   if (store.socketLocal.message !== '') {
  //     collectDefaultSocketEvent[store.socketLocal.message]('')
  //   }
  // }, [store.socketLocal.message])

  useEffect(() => {
    const QS = '' // для подключения к чат румам
    if (API_WS_URL !== undefined && API_WS_URL !== '' && absSocket === undefined) {
      absSocket = useInit(API_WS_URL, QS) // инициализация
      useSocketCollectCallbacks(absSocket, collectDefaultSocketEvent) // Устарновка дефолтных параметров и кастомных
    }
    return () => {
      //! обязательно закрывать соединениие  после размонтирования  компоненты
      absSocket?.socket?.close()
      clearTimeout(timer)
    }
  }, [count])

  return null
})

export default Socket
