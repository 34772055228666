import React from 'react'

import { TMessage } from '@/hooks/useTranslate'

import { IFilterButton } from '@/views/FilterButton/interfaces'
import {
  IPaginatedParams,
  IQueryParams,
} from '@/api/interfaces'
import { IButton } from '@/interfaces/Common'

import TooltipView from '@/components/Tooltip/view'
import Icon from '@/components/Icon'
import FilterButton from '@/views/FilterButton'
import SearchInput from '@/views/SearchInput'
import { Button } from '@/views/button'

import style from './style/index.module.scss'

interface IAddBtnProps extends Omit<IButton, 'children'> {
  tooltipContent: TMessage
}

interface IProps {
  handleParams: (
    params: IQueryParams | IPaginatedParams
  ) => void
  handleCreateBtn: () => void
  handleUpload: () => void
  filterBtnProps: Omit<IFilterButton, 'handleParams'>
  addBtnProps: IAddBtnProps
  createBtnTooltipContent: TMessage
}
/**
 * @view
 * * Вью панели с поиском и
 * * кнопками добавления и фильров на
 * * страницах оборудования и пользователей в деталке шкафа
 * @returns
 */
const SearchPanel: React.FC<IProps> = ({
  filterBtnProps,
  handleParams,
  handleCreateBtn,
  handleUpload,
  addBtnProps: { tooltipContent, ...btnProps },
  createBtnTooltipContent
}) => {
  return (
    <div className={style['search-panel']}>
      <SearchInput
        className={style['search-panel__search']}
        handleParams={handleParams}
      />
      <div className={style['search-panel__buttons']}>
        <TooltipView
          contetnt={{
            id: 'common.filter',
            defaultMessage: 'Фильтры',
          }}
        >
          <FilterButton
            handleParams={handleParams}
            {...filterBtnProps}
          />
        </TooltipView>
        <TooltipView contetnt={{
            id: 'button.upload',
            defaultMessage: 'Загрузить файл'
          }}>
          <Button
            className={style.btn__file}
            onClick={handleUpload}
          >
            <Icon src='file' />
          </Button>
        </TooltipView>
        <TooltipView contetnt={tooltipContent}>
          <Button
            className={style.btn__link}
            color='black'
            {...btnProps}
          >
            <Icon src='linkOn' />
          </Button>
        </TooltipView>
        <TooltipView contetnt={createBtnTooltipContent}>
          <Button
            className={style.btn__add}
            onClick={handleCreateBtn}
            color='black'
          >
            <Icon src='plus' />
          </Button>
        </TooltipView>
      </div>
    </div>
  )
}

export default SearchPanel
