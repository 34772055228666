import React, { RefObject } from 'react'
import { useIntl } from 'react-intl'
import classNames from 'classnames'
import { useStores } from '@/hooks'

import {
  TLoadOptions,
  IOptions,
} from '@/apps/Modals/ModalContent/interfaces'
import { IHidDefaultValue } from '@/api/interfaces'

import { ADDITIONAL, EMPTY_CONSTS } from '@/const'

import AsyncSelect from '@/components/AsyncSelect/AsyncSelect'
import Icon from '@/components/Icon'
import PaginateAsyncSelect from '@/components/AsyncSelect/PaginateAsyncSelect'
import { Button } from '@/views/button'

import style from '../../ModalContent/styles/index.module.scss'
import { isUndefined } from '@/utils'

/**
 * Вьюха одалки загрузки ексель
 * @view
 *
 * @param handleClose - метод для закрытия модалки
 * @param selectProps - пропсы для селекта
 * @param disabled - disabled для кнопки
 * @param handleLoad - метод для обработки загрузки ексель
 * @param inputKey - ключ для инпута
 * @param inputRef - реф на инпут
 * @param handleAccept - метод на кнопку принять
 * @param [selectProps.handleChange] - метод при изменение в селекте
 * @param [selectProps.loadOptions] - метод для загрузки опций
 * @param [selectProps.error] - значение ошибки
 */

interface ISelectProps {
  onChange: (payload: IOptions[] | IOptions) => void
  loadOptions: TLoadOptions
  error: string
}

interface IHidSelectProps {
  handleChange: (e: IHidDefaultValue | null) => void
  value?: IHidDefaultValue
}

interface IUploadExcelView {
  handleClose: () => void
  selectProps: ISelectProps
  disabled?: boolean
  handleLoad: (
    e: React.ChangeEvent<HTMLInputElement>
  ) => void
  inputKey: string
  inputRef: RefObject<HTMLInputElement>
  handleAccept: () => void
  label: string
  hidSelectProps?: IHidSelectProps
}

const UploadExcelView: React.FC<IUploadExcelView> = ({
  selectProps,
  handleClose,
  hidSelectProps,
  ...props
}) => {
  const { api } = useStores()
  const intl = useIntl()
  return (
    <div className={style.container}>
      <div
        className={classNames(
          style.container__header__icons
        )}
      >
        <Icon
          src='file'
          size='xl'
          className={classNames(
            style.container__header_icon,
            style.container__header_icon_orange
          )}
        />
        <Button
          variant='text'
          color='gray'
          onClick={handleClose}
        >
          <Icon src='close' />
        </Button>
      </div>
      <div className={style.container__body__status}>
        <h2 className={style.title}>
          {intl.formatMessage({
            id: 'button.upload',
            defaultMessage: 'Загрузить файл',
          })}
        </h2>
        <p className={style.container__body__status_text}>
          {intl.formatMessage({
            id: 'supportedFiles',
            defaultMessage:
              'Поддерживаемые файлы .xlsx, .xls',
          })}
        </p>
        <div className={style.container__upload_select}>
          <PaginateAsyncSelect
            defaultOptions
            getOptionValue={(option: IOptions) => option.id}
            getOptionLabel={(option: IOptions) =>
              option.label
            }
            hideSelectedOptions={false}
            label={props.label}
            placeholder={intl.formatMessage({
              id: 'withoutCupboard',
              defaultMessage: 'Без шкафа',
            })}
            noOptionsText={{
              id: 'noStorage',
              defaultMessage: 'Шкафы отсутствуют',
            }}
            additional={ADDITIONAL.PAGE}
            {...selectProps}
          />
        </div>
        {!isUndefined(hidSelectProps) && (
          <div
            className={classNames(
              style.container__upload_select,
              style.container__upload_select__format
            )}
          >
            <AsyncSelect
              {...hidSelectProps}
              label={intl.formatMessage({
                id: 'fileFormat',
                defaultMessage: 'Формат файла',
              })}
              isSearchable={false}
              withAllValue={false}
              handleFetchError={EMPTY_CONSTS.FUNC}
              loadFunction={api.user.getHidFormats}
            />
          </div>
        )}
      </div>
      <div className={style.container__buttons}>
        <Button
          className={style.container__buttons_cancel}
          onClick={handleClose}
        >
          {intl.formatMessage({
            id: 'common.cancel',
            defaultMessage: 'Отмена',
          })}
        </Button>
        <Button
          className={style.container__buttons_off}
          color='black'
          disabled={props.disabled}
          onClick={props.handleAccept}
        >
          <Icon src='download' />
          {intl.formatMessage({
            id: 'selectFile',
            defaultMessage: 'Выбрать файл',
          })}
        </Button>
      </div>
      <input
        name='file_GLS'
        type='file'
        ref={props.inputRef}
        key={props.inputKey}
        onChange={props.handleLoad}
        accept='.xlsx, .xls'
        style={{ display: 'none' }}
      />
    </div>
  )
}

export default UploadExcelView
