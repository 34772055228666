import { TStorageStatus } from '@/interfaces/consts'
import { STORAGE_STATUSES } from '@/apps/StorageList/const'
import { STORAGE_EDIT_STATUS_EDITING } from '../const'
import { isUndefined } from '@/utils'
import { EMPTY_CONSTS } from '@/const'

interface IGetStatusEditStorageProps {
  id: number | undefined
  error: boolean
  hasChangeImportantFields: boolean
  storageStatus: TStorageStatus
}

/**
 *
 * @func getStatusEditStorage
 * * метод для получения текущего состояния формы шкафа
 * @param id                         id текущего шкафа
 * @param hasChangeImportantFields   флаг говорящий о изменении важных файлов
 * @param error                      флаг говорящий об наличие ошибок в форме
 * @param storageStatus              текущий статус шкафа
 * @returns
 */
export const getStatusEditStorage = ({
  id,
  error,
  hasChangeImportantFields,
  storageStatus
}: IGetStatusEditStorageProps): string | null => {
  if (error || storageStatus === STORAGE_STATUSES.ERROR) {
    return STORAGE_EDIT_STATUS_EDITING.ERROR
  } else if (isUndefined(id)) {
    return STORAGE_EDIT_STATUS_EDITING.CREATE
  } else if (!isUndefined(id)) {
    if (hasChangeImportantFields) {
      return STORAGE_EDIT_STATUS_EDITING.EDIT
    }
    return STORAGE_EDIT_STATUS_EDITING.SUCCESS
  } else {
    return EMPTY_CONSTS.NULL
  }
}
