import React from 'react'

import { useIntl } from 'react-intl'
import cn from 'classnames'

import Badge from '@/components/Badge'
import Icon from '@/components/Icon'
import StorageStatus from '@/apps/StorageList/views/StorageStatus'
import { Button } from '@/views/button'
import { Typography } from '@/views/typography'

import { getInitials } from '@/utils'

import { USER_STORAGE_UNBIND_VARIANT } from '../../const'
import { DEFAULT_STORAGE_STATUS, DEFAULT_USER_ROLE, EMPTY_CONSTS } from '@/const'

import { IStorage, IUserGetValue, TUserRole } from '@/api/interfaces'
import { IUserStorageUnbindVariant } from '../../ModalContent/interfaces'

import style from './style/index.module.scss'

const content = {
  [USER_STORAGE_UNBIND_VARIANT.STORAGE_UNBIND]: ({ storageData }) => {
    const {
      title = EMPTY_CONSTS.STR,
      storage_status: storageStatus = DEFAULT_STORAGE_STATUS,
      count_cells: countCells = EMPTY_CONSTS.ZERO
    } = storageData
    return (
      <div className={cn(style.container__body, style.container__body_cells)}>
        <StorageStatus
          countCells={countCells}
          storageStatus={storageStatus}
        />
        <div className={style.container__body_storage}>
          <Typography
            weight={700}
            size={18}
            className={style.container__body_storage_text}
          >
            {title}
          </Typography>
        </div>
      </div>
    )
  },
  [USER_STORAGE_UNBIND_VARIANT.USER_UNBIND]: ({ userData }) => {
    const intl = useIntl()
    const {
      full_name: fullName,
      last_name: lastName = EMPTY_CONSTS.STR,
      first_name: firstName = EMPTY_CONSTS.STR,
      user_role: userRole = DEFAULT_USER_ROLE
    } = userData
    const title = getInitials(firstName, lastName)
    return (
      <div className={style.container__user}>
        <div className={style.container__user_body}>
          <Badge title={title} />
          <Typography weight={700} size={18} className={style.container__user_text}>
            {fullName ?? '-'}
          </Typography>
        </div>
        {intl.formatMessage({
          id: `user.user_role.${userRole as TUserRole}`,
          defaultMessage: 'Роль'
        })}
      </div>
    )
  }
}

interface IProps {
  storageData: IStorage
  modalType: IUserStorageUnbindVariant
  closeModal: () => void
  handleSubmit: () => void
  isDisabled?: boolean
  userData: IUserGetValue
}
/**
 * @view
 * * вью для отрисовки модалки отвязки пользователя от шкафа
 * * в зависимости от ее типа
 * @param modalType       - тип отоброжаемого контента
 * @param storageData     - текущий шкаф
 * @param userData        - текущий пользователь
 * @param closeModal      - метод закрытия модалкти
 * @param handleSubmit    - метод для какого либо запроса
 * @param isDisabled      - флаг для выкл кнопки сабмита
 */
const UserStorageUnbindView: React.FC<IProps> = ({
  modalType,
  storageData,
  userData,
  closeModal,
  handleSubmit,
  isDisabled
}) => {
  const intl = useIntl()
  const isUserUnbind = modalType === USER_STORAGE_UNBIND_VARIANT.USER_UNBIND
  const titleText = isUserUnbind
    ? {
        id: 'modal.title.user_unbind',
        defaultMessage: 'Вы точно хотите отвязать пользователя?'
      }
    : {
        id: 'modal.title.storage_unbind',
        defaultMessage: 'Вы точно хотите отвязать шкаф?'
      }

  return (
    <div className={style.container}>
      <div className={style.container__header}>
        <Icon
          src='linkOff'
          size='xl'
          className={style.container__header_icon}
          data-type={modalType}
        />
        <p className={style.container__header_lable}>
          {intl.formatMessage(titleText)}
        </p>
      </div>
      {content[modalType]({ userData, storageData })}
      <div className={style.container__buttons}>
        <Button
          className={style.container__buttons_cancel}
          onClick={closeModal}
        >
          {intl.formatMessage({
            id: 'common.cancel',
            defaultMessage: 'Cancel'
          })}
        </Button>
        <Button
          className={style.container__buttons_off}
          color='black'
          onClick={handleSubmit}
          disabled={isDisabled}
        >
          {intl.formatMessage({
            id: 'common.unbind',
            defaultMessage: 'Отвязать'
          })}
        </Button>
      </div>
    </div>
  )
}

export default UserStorageUnbindView
