export const STORAGE_EDIT_ACTION_BUTTON_TYPE = {
  EDIT: 'edit',
  CONNECTION: 'connection'
}

export const STORAGE_EDIT_STATUS_EDITING = {
  EDIT: 'edit',
  SUCCESS: 'success',
  CREATE: 'create',
  ERROR: 'error'
}

export const USER_EDIT_TYPE = {
  EDIT: 'edit',
  CREATE: 'create'
}

export const EQUIPMENT_EDIT_FIELDS = {
  LABEL_NUMBER: 'label_number',
  STORAGE_ID: 'storage_id',
  STORAGE: 'storage',
  EQUIPMENT_TYPE: 'equipment_type',
  USER_IDS: 'user_ids',
  TITLE: 'title',
  INVENTORY_NUMBER: 'inventory_number',
  DESCRIPTION: 'description',
  SERIAL_NUMBER: 'serial_number',
  STATUS: 'status',
  LABEL: 'label',
  CELL_NUMBER: 'cell_number'
} as const

export const MODAL_VARIANT_CONTENT = {
  DELETE: 'DELETE',
  UNBIND: 'UNBIND'
} as const

export const USER_STORAGE_UNBIND_VARIANT = {
  STORAGE_UNBIND: 'STORAGE_UNBIND',
  USER_UNBIND: 'USER_UNBIND'
} as const

export const EQUIPMENT_STATUS_CELL_COUNTS = [
  {
    id: 0,
    field: 'count_absent',
    type: 'absent',
    filterKey: 'absent'
  },
  {
    id: 1,
    field: 'count_on_hands',
    type: 'on_hands',
    filterKey: 'on_hands'
  }
] as const

export const DEFAULT_EQUIPMENT_STATUSES_DATA = {
  count_on_hands: 0,
  count_absent: 0
}

export const PROBLEM_EDIT_TYPE = { ...USER_EDIT_TYPE }

export const PROBLEM_EDIT_FIELDS = {
  EQUIPMENT_TYPES: 'equipment_types',
  TITLE: 'title',
  TYPE: 'type'
} as const
// кастомные data атрибуты для BaseModal
export const CUSTOM_DATA_ATTR_STYLES = {
  SCROLL:'scroll'
} as const