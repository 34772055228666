import React from 'react'
import {
  EQUIPMENT_STATUS,
  EQUIPMENT_STATUS_ICON
} from '@/const'
import Icon from '@/components/Icon'

export interface IEquipmentStatus {
  status: (typeof EQUIPMENT_STATUS)[keyof typeof EQUIPMENT_STATUS]
}

/**
 * Вюха для иконки статуса оборудования
 * @param status
 * @constructor
 */
const EquipmentStatus: React.FC<IEquipmentStatus> = ({
  status
}) => {
  const iconType =
    status === EQUIPMENT_STATUS.ON_HANDS
      ? EQUIPMENT_STATUS_ICON.ON_HANDS
      : EQUIPMENT_STATUS_ICON.ABSENT
  return <Icon src={iconType} size='small-xl' />
}

export default EquipmentStatus
