import React from 'react'
import {
  GxIcon,
  GxInput,
} from '@garpix/garpix-web-components-react'
import classNames from 'classnames'

import { useTranslate } from '@/hooks'
import {
  HiddenPasswordIcon,
  VisiblePasswordIcon,
  ErrorExclamation,
  SearchIcon,
  CloseIcon,
} from '@/assets/icon'
import { EMPTY_CONSTS } from '@/const'

import { IInput } from '@/interfaces/Common'

import style from './style/index.module.scss'

export const Input: React.ForwardRefExoticComponent<
  IInput & {
    children?: React.ReactNode
  } & React.RefAttributes<HTMLGxInputElement>
> = React.forwardRef(
  (
    {
      type = 'text',
      label = EMPTY_CONSTS.STR,
      error = EMPTY_CONSTS.FALSE,
      disabled = EMPTY_CONSTS.FALSE,
      errorMessage = EMPTY_CONSTS.STR,
      onChange = EMPTY_CONSTS.FUNC,
      onFocus = EMPTY_CONSTS.FUNC,
      onBlur = EMPTY_CONSTS.FUNC,
      outerStyles = '',
      ...props
    },
    ref: React.RefObject<HTMLGxInputElement>,
  ): React.ReactElement => {
    const { translate } = useTranslate()
    return (
      <GxInput
        ref={ref}
        label={label}
        data-error={error}
        disabled={disabled}
        className={classNames(style.input, outerStyles)}
        onGx-input={onChange}
        onGx-blur={onBlur}
        onGx-focus={onFocus}
        type={type}
        autocomplete="off"
        {...props}
      >
        <GxIcon
          slot="hide-password-icon"
          src={VisiblePasswordIcon}
          className={style.input__icon}
        />
        <GxIcon
          slot="show-password-icon"
          src={HiddenPasswordIcon}
          className={style.input__icon}
        />
        <GxIcon
          src={CloseIcon}
          slot="clear-icon"
          className={style.input__icon}
        />
        {type === 'search' ? (
          <GxIcon
            src={SearchIcon}
            slot="prefix"
            className={style.input__icon}
          />
        ) : null}
        {Boolean(error) && (
          <>
            <GxIcon src={ErrorExclamation} slot="suffix" />
            <p slot="help-text">
              {translate(errorMessage)}
            </p>
          </>
        )}
        {props.children}
      </GxInput>
    )
  },
)
