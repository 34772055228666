import React from 'react'

import UnlinkDropdown from '@/views/UnlinkDropdown'
import { Typography } from '@/views/typography'
import StorageStatus from '@/apps/StorageList/views/StorageStatus'

import { useStores } from '@/hooks'

import { EMPTY_CONSTS, MODAL_TYPES } from '@/const'
import { USER_STORAGE_UNBIND_VARIANT } from '@/apps/Modals/const'

import { IStorage } from '@/api/interfaces'
import { TUnlinkStorage } from '../../interfaces'

import styles from './styles/index.module.scss'

/**
 * @component CountCells
 * * компонент статуса шкафа c номером ячейки
 * @param
 */
export const CountCells = (
  _: string,
  storage: IStorage,
): JSX.Element => {
  return (
    <div className={styles.table__body_count}>
      <StorageStatus
        storageStatus={storage?.storage_status ?? false}
        countCells={storage?.count_cells}
      />
    </div>
  )
}

/**
 * @component Title
 * * компонент названия шкафа
 * @param
 */
export const Title = (title: string): JSX.Element => {
  return (
    <Typography
      className={styles.table__body_title}
      size={18}
      weight={700}
    >
      {title}
    </Typography>
  )
}

/**
 * @component UnlinkStorage
 * * компонент дропдауна отвязки шкафа от пользователя
 * @param id               - id шкафа
 * @param value            - обьект с id пользователя: { user_id: id }
 * @function refetch       - функция обновления списка после отвязки
 * @param storageData      - текущий шкаф
 * @param userData         - текущий пользователь
 * @param type             - тип отвязки (пользователь от шкафа или шкаф от пользователя)
 */
export const UnlinkStorage: TUnlinkStorage = ({
  refetch,
  storageData = EMPTY_CONSTS.OBJ,
  userData = EMPTY_CONSTS.OBJ,
  type = USER_STORAGE_UNBIND_VARIANT.STORAGE_UNBIND,
  handleUnbindMethod,
}): JSX.Element => {
  const { modalStore } = useStores()

  /**
   * @function handleOpenModal
   * * функция открытия модального окна для отвязывания шкафа от пользователя
   * @param
   */
  const handleOpenModal = (): void => {
    modalStore.open(MODAL_TYPES.USER_STORAGE_UNBIND, {
      type,
      storageData,
      userData,
      refetch,
      handleUnbindMethod,
    })
  }

  return <UnlinkDropdown onClick={handleOpenModal} />
}
