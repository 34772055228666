import React from 'react'

import { useIntl } from 'react-intl'

interface IProps {
  countCells: number | null
}

/**
 * @component Succes
 * * компонент таблицы Шкафов. Реализует интерфес для Succes статуса шкафа
 * @param countCells    колл ячеек
 */

const Succes: React.FC<IProps> = ({ countCells }) => {
  const intl = useIntl()
  return (
    <>
      {countCells !== null ? <span>{countCells}</span> : null}
      <p>
        {intl.formatMessage({
          id: 'storage.cells',
          defaultMessage: 'cells'
        })}
      </p>
    </>
  )
}

export default Succes
