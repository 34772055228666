import React from 'react'
import { useIntl } from 'react-intl'
import { GxIcon } from '@garpix/garpix-web-components-react'

import { Typography } from '@/views/typography'
import { Button } from '@/views/button'
import Icon from '@/components/Icon'

import { GroupUsers } from '@/assets/icon'
import { isUndefined } from '@/utils'

import styles from '../styles/index.module.scss'

interface IProps {
  closeModal: () => void
  groupId?: number
}

const Header: React.FC<IProps> = ({closeModal, groupId}) => {
  const intl = useIntl()

  const titleText = !isUndefined(groupId) ? {
    id: 'group.editGroup',
    defaultMessage: 'Редактировать группу',
  } : {
    id: 'group.newGroup',
    defaultMessage: 'Новая группа',
  }

  return (
    <div className={styles['group-edit__header']}>
      <div data-edit={Boolean(groupId)} className={styles['group-edit__header_icon']}>
        <GxIcon src={GroupUsers}/>
      </div>
      <Typography size={32}>
        {intl.formatMessage(titleText)}
      </Typography>
      <Button
        className={styles['group-edit__header_close']}
        variant='text'
        onClick={closeModal}
      >
        <Icon src='close' />
      </Button>
    </div>
  )
}

export default Header
