import React from 'react'
import BaseModal from './Base'
import { useStores } from '@/hooks'
import { NoSSR } from '@/components/NoSSR'
import { EMPTY_CONSTS, MODAL_TYPES } from '@/const'
import { observer } from 'mobx-react'
import {
  EquipmentUnlinkError,
  FileUploadError,
  LogOut,
  StorageTurnOff,
  UserDelete,
  StorageEdit,
  EquipmentDelete,
  UserEdit,
  EquipmentFilter,
  UserFilter,
  StorageFilter,
  Custom,
  UploadUserExcel,
  UploadEquipmentExcel,
  UserStoragesBind,
  ChangePassword,
  RecoveryPassword,
  NewPassword,
  SuccessPassword,
  EquipmentEdit,
  SetNewMail,
  CheckCode,
  SuccessEditEmail,
  EquipmentUnbind,
  UserEquipmentsBind,
  SuccessUploadExcel,
  EquipmentBindToStorage,
  UserStorageUnbind,
  WarningError,
  StorageUserFilter,
  StorageSync,
  DownloadHistory,
  ProblemEdit,
  UserGroupEdit,
  GroupUserStoragesBind,
  EquipmentBindToGroupUser,
  UserBindingToStorage,
  UserBindingToGroupUser,
  SynchronizationSkud,
  UserGroupDelete,
} from './ModalContent'
import SuccessRecoveryProfile from './ModalContent/SuccessEditProfile'

/**
 * набор модалок
 * key- тип модалки
 * value - компонента контента модалки
 *
 */
const modals = {
  // * [DEFAULT MODAL]
  [MODAL_TYPES.EMPTY]: () => EMPTY_CONSTS.NULL,
  [MODAL_TYPES.STORAGE_TURN_OFF]: StorageTurnOff,
  [MODAL_TYPES.EQUIPMENT_DELETE]: EquipmentDelete,
  [MODAL_TYPES.USER_DELETE]: UserDelete,
  [MODAL_TYPES.LOG_OUT]: LogOut,
  [MODAL_TYPES.EQUIPMENT_UNLINK_ERROR]:
    EquipmentUnlinkError,
  [MODAL_TYPES.FILE_UPLOAD_ERROR]: FileUploadError,
  [MODAL_TYPES.STORAGE_EDIT]: StorageEdit,
  [MODAL_TYPES.USER_BINDING]: UserBindingToStorage,
  [MODAL_TYPES.USER_EDIT]: UserEdit,
  [MODAL_TYPES.EQUIPMENT_FILTER]: EquipmentFilter,
  [MODAL_TYPES.USER_FILTER]: UserFilter,
  [MODAL_TYPES.STORAGE_FILTER]: StorageFilter,
  [MODAL_TYPES.CUSTOM]: Custom,
  [MODAL_TYPES.UPLOAD_USER_EXCEL]: UploadUserExcel,
  [MODAL_TYPES.UPLOAD_EQUIPMENT_EXCEL]:
    UploadEquipmentExcel,
  [MODAL_TYPES.USER_STORAGES_BIND]: UserStoragesBind,
  [MODAL_TYPES.CHANGE_PASSWORD]: ChangePassword,
  [MODAL_TYPES.RECOVERY_PASSWORD]: RecoveryPassword,
  [MODAL_TYPES.NEW_PASSWORD]: NewPassword,
  [MODAL_TYPES.SUCCES_EDIT_PASSWORD]: SuccessPassword,
  [MODAL_TYPES.USER_EQUIPMENTS_BIND]: UserEquipmentsBind,
  [MODAL_TYPES.EQUIPMENT_EDIT]: EquipmentEdit,
  [MODAL_TYPES.EDIT_EMAIL]: SetNewMail,
  [MODAL_TYPES.CHECK_EMAIL_CODE]: CheckCode,
  [MODAL_TYPES.SUCCESS_EDIT_EMAIL]: SuccessEditEmail,
  [MODAL_TYPES.SUCCESS_EDIT_PROFILE]:
    SuccessRecoveryProfile,
  [MODAL_TYPES.EQUIPMENT_UNBIND]: EquipmentUnbind,
  [MODAL_TYPES.SUCCESS_UPLOAD_EXCEL]: SuccessUploadExcel,
  [MODAL_TYPES.WARNING_ERROR]: WarningError,
  [MODAL_TYPES.EQUIPMENT_BIND]: EquipmentBindToStorage,
  [MODAL_TYPES.USER_STORAGE_UNBIND]: UserStorageUnbind,
  [MODAL_TYPES.DOWNLODA_HISTORY]: DownloadHistory,
  [MODAL_TYPES.STORAGE_USER_FILTER]: StorageUserFilter,
  [MODAL_TYPES.STORAGE_SYNC]: StorageSync,
  [MODAL_TYPES.PROBLEM_EDIT]: ProblemEdit,
  [MODAL_TYPES.SYNCHRONIZATION]: SynchronizationSkud,
  [MODAL_TYPES.USER_GROUP_EDIT]: UserGroupEdit,
  [MODAL_TYPES.USER_GROUP_BIND_STORAGES]:
    GroupUserStoragesBind,
  [MODAL_TYPES.USER_GROUP_BIND_EQUIPMENT]:
    EquipmentBindToGroupUser,
  [MODAL_TYPES.USER_GROUP_BIND_USER]:
    UserBindingToGroupUser,
  [MODAL_TYPES.USER_GROUP_DELETE]: UserGroupDelete,
}

const Modals: React.FC = observer(() => {
  const { modalStore } = useStores()

  const afterHideModal = (): void => modalStore.close()

  const closeModal = (): void => modalStore.close()

  const CurrentModal =
    modals[modalStore.currentVariant ?? MODAL_TYPES.EMPTY]

  if (CurrentModal === null || CurrentModal === undefined) {
    return null
  }

  return (
    <NoSSR>
      <BaseModal
        handleClose={closeModal}
        open={modalStore.isOpen}
        onGx-after-hide={afterHideModal}
        theme={modalStore?.modalProps?.theme}
        data-custom-styles={modalStore?.customDataStyles}
      >
        <CurrentModal
          modalProps={modalStore.modalProps}
          closeModal={closeModal}
        />
      </BaseModal>
    </NoSSR>
  )
})

export default Modals
