import React from 'react'
import { formatDateForFrontend, getInitials } from '@/utils'
import { EQUIPMENT_PROBLEM_STATUS } from '@/const'
import Icon from '@/components/Icon'
import { Typography } from '@/views/typography'
import UserBadge from '@/views/UserBadge'

import styles from './styles/index.module.scss'

export interface IProblemHeadProps {
  status: string
  createdAt: string
  title: string
  firstName: string
  lastName: string
}

/**
 * Шапка для Акордиона проблемы в списке проблем деталки оборудования
 * @param status - статус пробле
 * @param createdAt - когда обновлено
 * @param title - тайтл проблемы
 * @param firstName - имя пользователя
 * @param lastName - фамилия пользователя
 * @constructor
 */

// TODO: обработать статус
const ProblemHeadView: React.FC<IProblemHeadProps> = ({
  status,
  createdAt,
  title,
  firstName,
  lastName
}) => {
  const initials = getInitials(firstName, lastName)
  const formatedUpdatedAt = formatDateForFrontend(createdAt)
  const statusIcon = status === EQUIPMENT_PROBLEM_STATUS.RESOLVED
    ? 'statusResolved'
    : 'statusBroken'
  return (
    <div className={styles.head__root}>
      <div className={styles.head__problem}>
        <Icon
          src={statusIcon}
          className={styles.head__problem_status}
        />
        <div>
          <Typography size={14} weight={400} color='b-60'>
            {formatedUpdatedAt}
          </Typography>
          <Typography size={16} weight={400} color='black'>
            {title}
          </Typography>
        </div>
      </div>
      <UserBadge size={18} weight={700} title={initials}>
        <span className={styles.head__problem_user}>
          {`${lastName} ${firstName}`}
        </span>
      </UserBadge>
    </div>
  )
}

export default ProblemHeadView
