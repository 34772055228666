import React, { RefObject } from 'react'

import cn from 'classnames'

import ProblemsEmpty from '@/apps/RightSidebar/views/EquipmentSideBarView/ProblemsEmpty'

import { isNull } from '@/utils'

import { EMPTY_CONSTS } from '@/const'

import { IEquipment } from '@/api/interfaces'

import styles from './styles/index.module.scss'

export interface IProblemsList {
  listRef: RefObject<HTMLUListElement>
  handleScroll: () => void
  count: number
  children?: React.ReactNode
  isSearch: boolean
  equipmentData: IEquipment
}

/**
 * Скрощийся список контейнер для проблем
 * @view
 * @param count - количство проблем
 * @param listRef - ссылка на скрощийся список
 * @param handleScroll - метод для скрола
 * @param children
 * @param isSearch - флаг о наличии данных через поиск
 * @param equipmentData - текущее оборудование
 */
const ProblemsList: React.FC<IProblemsList> = ({
  count,
  listRef,
  handleScroll,
  children,
  isSearch,
  equipmentData
}) => {
  const isCellNumber = !isNull(equipmentData?.cell_number)

  if (count === EMPTY_CONSTS.ZERO) {
    return <ProblemsEmpty isSearch={isSearch} />
  }

  return (
    <ul
      ref={listRef}
      onScroll={handleScroll}
      className={cn(styles.list, {
        [styles.list_addwrap]: isCellNumber
      })}
    >
      {children}
    </ul>
  )
}

export default ProblemsList
