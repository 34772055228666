import React from 'react'
import { useIntl } from 'react-intl'
import { IGroupUser } from '@/api/interfaces/GroupUsers'
import { isNull, isUndefined } from '@/utils'

import AvatarGroup from '@/views/AvatarGroup'

import styles from './styles/index.module.scss'

interface IProps {
  users?: IGroupUser[] | null
}
/**
 * * Въюха Аватарок пользователй с заглушкой если их нет 
 * @view
 */
const UsersAvatar: React.FC<IProps> = ({ users }) => {
  const intl = useIntl()

  if (isNull(users) || isUndefined(users)) {
    return (
      <div className={styles.users__empty}>
        <span>
          {intl.formatMessage({
            id: 'noUsers',
            defaultMessage: 'Нет пользователей',
          })}
        </span>
      </div>
    )
  }

  return <AvatarGroup users={users} max={6} />
}

export default UsersAvatar
