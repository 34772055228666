import React, { RefObject } from 'react'

import cn from 'classnames'

import Table from '@/apps/Table/component/Table'
import { SidebarContainer } from '@/apps/RightSidebar/views/SidebarContainer'
import SearchInput from '@/views/SearchInput'
import TableWrapper from '@/views/TableWrapper'
import UserRole from '@/views/UserRole'
import UserBadge from '@/views/UserBadge'
import EquipmentStatus from '@/views/EquipmentStatus'
import UnlinkDropdown from '@/views/UnlinkDropdown'
import { Spinner } from '@/views/spinner'

import { getInitials, isNull } from '@/utils'

import {
  EMPTY_CONSTS,
  TABLE_PAGE,
  TABLE_WRAPPER_TYPE,
} from '@/const'
import { EQUIPMENT_COLUMNS } from '@/apps/RightSidebar/const'

import {
  IEquipment,
  ITiedEquipmentUser,
  ITiedEquipmentUsersListParams,
  TEquipmentStatus,
  TUser,
  TUserRole,
} from '@/api/interfaces'

import styles from './styles/index.module.scss'

const tableStyles = {
  classNameTable: styles.table,
  classNameBodyRow: styles.table__body_row,
}

interface IUserPanelView {
  handleFetch: (
    params: ITiedEquipmentUsersListParams,
  ) => void
  listRef: RefObject<HTMLDivElement>
  handleScroll: () => void
  isLoading: boolean
  data?: ITiedEquipmentUser[]
  error?: string[] | null
  params: ITiedEquipmentUsersListParams
  handleUnbind: (user: TUser) => void
  handleEdit: () => void
  equipmentData: IEquipment
  isScrollEnd: boolean
}

/**
 * Вюха панели пользователей деталки оборудования
 * @param listRef - реф на  обертку списка пользователей
 * @param isLoading
 * @param data - список пользователей
 * @param error
 * @param params - текущие парамметры запроса
 * @param handleFetch - фетч для поиска
 * @param handleScroll - обработчик скрола на фетч списка
 * @param handleUnbind - отвязка пользователя от оборудования по id
 * @param equipmentData - текущее оборудование
 * @param isScrollEnd   - флаг доскроллено ли до самого низа контейнера
 * @constructor
 */
const UsersPanelView: React.FC<IUserPanelView> = ({
  listRef,
  isLoading,
  data,
  error,
  params,
  handleFetch,
  handleScroll,
  handleUnbind,
  handleEdit,
  equipmentData,
  isScrollEnd,
}) => {
  const isSearchPanel =
    !isLoading &&
    (Boolean(params.name) || Boolean(data?.length))
  const isCellNumber = !isNull(equipmentData?.cell_number)
  const columns = [
    {
      key: EQUIPMENT_COLUMNS.FULL_NAME,
      contentHeader: EMPTY_CONSTS.STR,
      bodyCellProps: {
        render: (fullName: string, props: TUser) => {
          const initials = getInitials(
            props.first_name,
            props.last_name,
          )
          return (
            <UserBadge
              size={18}
              title={initials}
              weight={700}
              className={styles['users-panel__badge']}
            >
              {fullName}
            </UserBadge>
          )
        },
      },
    },
    {
      key: EQUIPMENT_COLUMNS.USER_ROLE,
      contentHeader: EMPTY_CONSTS.STR,
      bodyCellProps: {
        className: styles['users-panel__user-role'],
        render: (role: TUserRole) => (
          <UserRole role={role} />
        ),
      },
    },
    {
      key: EQUIPMENT_COLUMNS.EQUIPMENT_STATUS,
      contentHeader: EMPTY_CONSTS.STR,
      bodyCellProps: {
        render: (status: TEquipmentStatus) => (
          <EquipmentStatus status={status} />
        ),
      },
    },
    {
      key: EQUIPMENT_COLUMNS.ID,
      contentHeader: EMPTY_CONSTS.STR,
      bodyCellProps: {
        render: (_: number, user: TUser) => {
          const handleClick = (): void => handleUnbind(user)
          return <UnlinkDropdown onClick={handleClick} />
        },
      },
    },
  ]
  return (
    <div
      className={styles['users-panel__root']}
      data-scrollend={isScrollEnd}
    >
      {isSearchPanel && (
        <SidebarContainer>
          <SearchInput handleParams={handleFetch} />
        </SidebarContainer>
      )}
      {isLoading ? (
        <>
          <Spinner isFullPage />
        </>
      ) : (
        <TableWrapper
          iconSrc={TABLE_PAGE.USER}
          search={params.name}
          type={TABLE_WRAPPER_TYPE.EQUIPMENT_USER_TAB}
          length={data?.length}
          error={error}
          handleClick={handleEdit}
        >
          <div
            ref={listRef}
            className={cn(styles['users-panel__list'], {
              [styles['users-panel__list_addwrap']]:
                isCellNumber,
            })}
            onScroll={handleScroll}
          >
            <Table
              rowsData={data}
              columns={columns}
              styles={tableStyles}
            />
          </div>
        </TableWrapper>
      )}
    </div>
  )
}

export default UsersPanelView
