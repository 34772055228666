import React, { useLayoutEffect, useState } from 'react'
import AutoSizer from 'react-virtualized-auto-sizer'
import classNames from 'classnames'

import SearchBindPanel from '../../components/SearchBindPanel'
import TableWrapper from '@/views/TableWrapper'

import { ISearchBindPanel } from '../../interfaces'
import { ITableWrapper } from '@/views/TableWrapper/interfaces'

import styles from './styles/index.module.scss'

export type TUserGroupTableWrapProps = Omit<
  ITableWrapper,
  'children'
>

export interface ITabPanelWrapProps {
  searchPanelProps: ISearchBindPanel
  tableWrapProps: TUserGroupTableWrapProps
  isScrollEnd: boolean
  containerRef: React.RefObject<HTMLDivElement>
  tableScroll: () => void
  loadedData?: unknown[]
  isLoading: boolean
}
/**
 * @view
 * * Вюха для таблиц в деталках чего либо с поиском и скролом
 */
const TabPanelWrap: React.FC<
  ITabPanelWrapProps & { children: React.ReactNode }
> = ({
  isScrollEnd,
  searchPanelProps,
  tableWrapProps,
  containerRef,
  tableScroll,
  isLoading,
  loadedData,
  children,
}) => {
  const [showSearchPanel, setShowSearchPanel] =
    useState(false)

  useLayoutEffect(() => {
    if (
      Boolean(loadedData?.length) &&
      !isLoading &&
      !showSearchPanel
    )
      setShowSearchPanel(true)
  }, [isLoading, loadedData?.length])

  return (
    <div
      className={styles.container}
      data-scrollend={isScrollEnd}
    >
      {showSearchPanel && (
        <SearchBindPanel {...searchPanelProps} />
      )}
      <TableWrapper {...tableWrapProps}>
        <div className={styles.container__size_wrap}>
          <AutoSizer>
            {({ height, width }) => (
              <div
                ref={containerRef}
                onScroll={tableScroll}
                className={classNames(
                  styles.container__wrap,
                )}
                style={{
                  height,
                  width,
                }}
              >
                {children}
              </div>
            )}
          </AutoSizer>
        </div>
      </TableWrapper>
    </div>
  )
}

export default TabPanelWrap
