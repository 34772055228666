import React, { RefObject } from 'react'

import {
  IEquipment,
  IEquipmentProblem,
  IEquipmentProblemsParams
} from '@/api/interfaces'
import { EMPTY_CONSTS } from '@/const'

import ProblemsList from '@/apps/RightSidebar/views/EquipmentSideBarView/ProblemsList'
import Accordion from '@/views/Accordion'
import DateInputs from '@/apps/RightSidebar/views/EquipmentSideBarView/DateInputs'
import { SidebarContainer } from '@/apps/RightSidebar/views/SidebarContainer'
import ProblemHeadView, {
  IProblemHeadProps
} from '@/apps/RightSidebar/views/EquipmentSideBarView/ProblemHead'
import { Spinner } from '@/views/spinner'

import styles from './styles/index.module.scss'

interface IUserPanelView {
  handleDownload: () => void
  handleStartChange: (date: string) => void
  handleEndChange: (date: string) => void
  handleFetch: (params: IEquipmentProblemsParams) => void
  listRef: RefObject<HTMLUListElement>
  handleScroll: () => void
  isLoading: boolean
  data: IEquipmentProblem[] | undefined
  error?: string[] | null
  params: IEquipmentProblemsParams
  isReportLoading: boolean
  startDate?: string
  endDate?: string
  equipmentData: IEquipment
  isScrollEnd: boolean
}

/**
 * Вьха панели списка проблем в деталке оборудования
 * @param data - список проблемм
 * @param isLoading - статус загрузки
 * @param startDate - стартовая дата
 * @param endDate - конечная дата
 * @param handleEndChange - метод выбора конечной даты
 * @param handleStartChange - метод выбора стартовой даты
 * @param handleDownload - метод кнопки сформировать очтет
 * @param listRef - ссылка на скролящийся список проблем
 * @param handleScroll - метод для скролящегося списка проблем
 * @param equipmentData - текущее оборудование
 * @param isScrollEnd - флаг доскроллено ли до самого низа контейнера
 * @constructor
 */

const ProblemsPanelView: React.FC<IUserPanelView> = ({
  data = EMPTY_CONSTS.ARR,
  isLoading,
  startDate,
  endDate,
  handleEndChange,
  handleStartChange,
  handleDownload,
  listRef,
  handleScroll,
  isReportLoading,
  equipmentData,
  isScrollEnd
}) => {
  const renderHead = (
    props: IProblemHeadProps
  ): React.ReactElement => <ProblemHeadView {...props} />
  const isShowSidebarContainer =
    (data.length !== EMPTY_CONSTS.ZERO || Boolean(startDate) || Boolean(endDate))

  return (
    <div
      className={styles.problems__root}
      data-scrollend={isScrollEnd}
    >
      {isShowSidebarContainer && (
        <SidebarContainer>
          <div className={styles.problems__date}>
            <DateInputs
              startDate={startDate}
              endDate={endDate}
              handleEndChange={handleEndChange}
              handleStartChange={handleStartChange}
              handleDownload={handleDownload}
              isReportLoading={isReportLoading}
            />
          </div>
        </SidebarContainer>
      )}
      {isLoading && data.length === EMPTY_CONSTS.ZERO
        ? (
          <Spinner isFullPage />
          )
        : (
          <ProblemsList
            isSearch={isShowSidebarContainer}
            count={data.length}
            listRef={listRef}
            handleScroll={handleScroll}
            equipmentData={equipmentData}
          >
            {data.map((problem, index) => (
              <li
                className={styles.problems__item}
                key={index}
              >
                <Accordion
                  head={renderHead({
                    lastName: problem.last_name,
                    firstName: problem.first_name,
                    title: problem.title,
                    createdAt: problem.created_at,
                    status: problem.status
                  })}
                >
                  {problem.description}
                </Accordion>
              </li>
            ))}
          </ProblemsList>
          )}
    </div>
  )
}

export default ProblemsPanelView
