import React, { FC } from 'react'
import classNames from 'classnames'
import style from './style/index.module.scss'

interface SwitchType {
  handleChange: React.ChangeEventHandler<HTMLInputElement>
  handleBlur?: React.FocusEventHandler<HTMLInputElement>
  checked: boolean
  children?: React.ReactNode
  name?: string
  disabled?: boolean
}

export const Switch: FC<SwitchType> = (props) => {
  const { handleChange, checked, name, handleBlur, disabled } = props

  return (
    <div className={style.switch__flex}>
      {props?.children}
      <div
        data-disabled={disabled}
        className={classNames(style.switch, {
          [style.switch_active]: checked
        })}
      >
        <input
          checked={checked}
          onChange={handleChange}
          onBlur={handleBlur}
          type='checkbox'
          disabled={disabled}
          name={name}
          className={style.switch__input}
        />
      </div>
    </div>
  )
}
