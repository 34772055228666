import React from 'react'
import { observer } from 'mobx-react'
import NotificationBase from './Base'
import { NoSSR } from '@/components/NoSSR'
import { useStores } from '@/hooks'
import { DownloadReport } from './NotificationContent'
import {
  EMPTY_CONSTS,
  MODAL_TYPES,
  NOTIFICATION_TYPES
} from '@/const'

/**
 * набор уведомлений
 * key- тип уведомления
 * value - компонента контента уведомления
 *
 */

const NOTIFICATIONS = {
  [NOTIFICATION_TYPES.DOWNLOAD_REPORT]:
    DownloadReport,
  [MODAL_TYPES.EMPTY]: () => EMPTY_CONSTS.NULL
}

const Notification = observer(() => {
  const { notifyStore } = useStores()

  return (
    <NoSSR>
      {notifyStore.notifications.map(
        ({ id, type, ...props }, index) => {
          const CurrentNotification =
            NOTIFICATIONS[type ?? NOTIFICATION_TYPES.NULL]
          if (
            CurrentNotification === EMPTY_CONSTS.NULL ||
            CurrentNotification === EMPTY_CONSTS.UNDEFINED
          ) {
            return null
          }
          return (
            <NotificationBase
              {...props}
              key={id}
              onClose={() =>
                notifyStore.deleteNotification(index)}
            >
              <CurrentNotification
                {...props}
                onClose={() =>
                  notifyStore.setClosing(index)}
              />
            </NotificationBase>
          )
        }
      )}
    </NoSSR>
  )
})

export default Notification
