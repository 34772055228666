import React from 'react'
import { useIntl } from 'react-intl'
import cn from 'classnames'
import { Dropdown, DropdownMenu } from '@/components/Dropdown'
import MenuItem from '@/components/MenuItem'
import Icon from '@/components/Icon'
import styles from './styles/index.module.scss'

export type TEditDropdownClick = (type: 'delete' | 'edit', id: string) => void

interface IEditDropdown {
  hadnleEdit?: () => void
  handleDelete?: () => void
}
/**
 * @component EditDropdown
 * * компонент формы проверки ввода кода из почты
 * @param {ICheckCode}      общий интерфейс входных параметров EditDropdown
 *
 * @param id                id обьекта
 * @param onClick           функция которая вызывается при клике на редактирование\удаление
 */
const EditDropdown: React.FC<IEditDropdown> = ({ hadnleEdit, handleDelete }) => {
  const intl = useIntl()

  return (
    <div className={styles.dropdown}>
      <Dropdown>
        <Icon src='more' slot='trigger' />
        <DropdownMenu className={styles.dropdown__menu}>
          <MenuItem value='edit' onClick={hadnleEdit}>
            <div className={cn(styles.dropdown__edit, styles.dropdown__elem)}>
              <div className={styles.dropdown__elem_content}>
                <Icon src='edit' className={styles.dropdown__elem_content_icon} />
                {intl.formatMessage({ id: 'common.edit', defaultMessage: 'Изменить' })}
              </div>
            </div>
          </MenuItem>
          <MenuItem value='delete' onClick={handleDelete}>
            <div className={cn(styles.dropdown__delete, styles.dropdown__elem)}>
              <div className={styles.dropdown__elem_content}>
                <Icon src='delete' className={styles.dropdown__elem_content_icon} />
                {intl.formatMessage({ id: 'common.delete', defaultMessage: 'Удалить' })}
              </div>

            </div>
          </MenuItem>
        </DropdownMenu>
      </Dropdown>
    </div>
  )
}

export default EditDropdown
