import React from 'react'
import { useIntl } from 'react-intl'

import IntegrationStatus from '../IntegrationStatus'
import { Typography } from '@/views/typography'

import styles from './style/index.module.scss'
/**
 * @view
 * * шапка скуда
 */
const Header = (): React.ReactElement => {
  const intl = useIntl()
  return (
    <header className={styles.header}>
      <div className={styles.header__status}>
        <IntegrationStatus />
      </div>
      <Typography
        className={styles.header__title}
        size={32}
      >
        {intl.formatMessage({
          id: 'integrationWithScud',
          defaultMessage: 'Интеграция со СКУД'
        })}
      </Typography>
    </header>
  )
}

export default Header
