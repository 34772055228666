import { STORAGE_STATUSES } from '../StorageList/const'

export const STATUSES = {
  SYNC: 'sync',
  CONNECTED: 'connected',
  NOT_CONNECTED: 'not_connected'
}

export const STATUSES_LEFT_SIDEBAR = {
  SYNC: {
    STATUS: STORAGE_STATUSES.SYNCHRONIZATION_FRONTEND,
    TITLE: {
      id: 'sidebar.sync',
      defaultMessage: 'unknown lang'
    }
  },
  CONNECTED: {
    STATUS: STORAGE_STATUSES.SUCCES,
    TITLE: {
      id: 'sidebar.connected',
      defaultMessage: 'unknown lang'
    }
  },
  NOT_CONNECTED: {
    STATUS: STORAGE_STATUSES.ERROR,
    TITLE: {
      id: 'sidebar.not_connected',
      defaultMessage: 'unknown lang'
    }
  }
}

export const SIDEBAR_TYPES = {
  STORAGE_DETAILS: 'storageDetails',
  EMPTY: 'empty'
}

export const STORAGE_SIDEBAR_COUNTERS = [
  {
    id: 1,
    iconName: 'equipment',
    title: {
      id: 'header.equipment',
      defaultMessage: 'Оборудование'
    },
    count: 'count_equipments'
  },
  {
    id: 2,
    iconName: 'user',
    title: {
      id: 'header.users',
      defaultMessage: 'Пользователи'
    },
    count: 'count_users'
  },
  {
    id: 3,
    iconName: 'lastconnect',
    title: {
      id: 'common.lastconnect',
      defaultMessage: 'Последнее подключение'
    },
    count: 'date_of_last_message',
    isCustomVisually: true,
    optionsVisually: {
      classname: 'dateoflastconnect'
    }
  }
]

export const COUNTER_STORAGE_COLOR = {
  ORANGE: 'orange',
  GRAY: 'gray'
}

export const STORAGE_FIELDS = {
  IS_CELL_RESERVATION: 'is_cell_reservation',
  IS_HAND_OVER: 'is_hand_over',
  EQUIPMENT_SELECTION_MODE: 'equipment_selection_mode',
  USER_SELECTION_MODE: 'user_selection_mode'
}

export const SHOWING_PANELS = {
  EDIT: 'edit',
  SETTINGS: 'settings'
} as const

export const EQUIPMENT_SELECTION_MODE_VARIANTS = {
  RANDOM: 'random',
  MANUALLY: 'manually'
} as const

export const USER_SELECTION_MODE_VARIANTS = {
  RANDOM: 'random',
  TIED_EQUIPMENT: 'tied_equipment'
} as const
