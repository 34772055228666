import React, { FC } from 'react'
import classNames from 'classnames'
import style from './style/index.module.scss'

export interface ITypography {
  size?: 300 | 46 | 40 | 36 | 32 | 30 | 24 | 18 | 16 | 14 | 13 | 11
  weight?: 400 | 700 | 500
  color?: 'red' | 'gray' | 'empty' | 'b-60' | 'black'
  className?: string
  slot?: string
  children: React.ReactNode
}

export const Typography: FC<ITypography> = (props) => {
  const {
    size = 16,
    color = '',
    weight = '',
    children,
    slot,
    className
  } = props

  const currentSize = `typography_${size}`
  const currentWeight = `typography__wight_${weight}`
  const currentColor = `typography__color_${color}`

  return (
    <p
      slot={slot}
      className={classNames(
        style.typography,
        {
          [style[currentSize]]: size,
          [style[currentWeight]]: weight,
          [style[currentColor]]: color
        },
        className
      )}
    >
      {children}
    </p>
  )
}
