import React from 'react'
import classNames from 'classnames'
import { GxIcon } from '@garpix/garpix-web-components-react'
import { IIconProps, IconType } from './interfaces'
import style from './styles/index.module.scss'

/**
 * @component Icon
 * * компонент иконок
 * @param {IIconProps} общий интерфейс входных параметров Icon
 *
 * @param src          задает иконку (внешний вид)
 * @param size         размер
 * @param className    дополнительные стили
 * @param props        дополнительные параметры (пропсы)
 */
const Icon = ({
  src,
  size = 'medium',
  className = '',
  ...props
}): React.ReactElement<IIconProps> => {
  const iconClasses = classNames({
    [style.icon]: true,
    [style[`icon--${size}`]]: size,
    [className]: Boolean(className)
  })

  const iconSrc = IconType[src]

  return <GxIcon className={iconClasses} src={iconSrc} {...props} />
}

export default Icon
