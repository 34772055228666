import React, { useMemo } from 'react'

import { formatDateForFrontend } from '@/utils'

import Icon from '@/components/Icon'

import style from './style/index.module.scss'

interface IProps {
  date: string
  showIcon?: boolean
}

/**
 *  * Вью для таблицы Шкафов,
 *  * отрисовывает дату изменения и кнопку для редактирования
 * @view EditWithTime
 *
 * @param date -        дата изменения
 * @param showIcon
 */

const EditWithTime: React.FC<IProps> = ({ date, showIcon = true }) => {
  const formatedDate = useMemo(
    () => formatDateForFrontend(date),
    [date]
  )
  return (
    <div className={style.editor}>
      {showIcon && <Icon src='edit' size='medium-adaptive' />}
      <span className={style.editor__time}>
        {formatedDate}
      </span>
    </div>
  )
}

export default EditWithTime
