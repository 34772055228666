import React from 'react'
import { useIntl } from 'react-intl'

import Icon from '@/components/Icon'
import { Button } from '@/views/button'

import style from './styles/index.module.scss'

export interface IUserDeleteProps {
  title: string
  color: string
  isDisabled: boolean
  handleDelete: () => void
  closeModal: () => void
}
/**
 * @view
 * * Вью маодлаки удаления группы пользователей
 */
const UserGroupDeleteView: React.FC<IUserDeleteProps> = ({
  title,
  color,
  isDisabled,
  closeModal,
  handleDelete,
}) => {
  const intl = useIntl()
  return (
    <div className={style.container}>
      <div className={style.container__header}>
        <Icon
          src="delete"
          size="xl"
          className={style.container__header_icon}
        />
        <p className={style.container__header_lable}>
          {intl.formatMessage({
            id: 'deleteGroupUser',
            defaultMessage:
              'Вы действительно хотите удалить группу?',
          })}
        </p>
      </div>
      <div className={style.container__group}>
        <div className={style.container__group__body}>
          <div
            className={style.container__group__color}
            style={{ backgroundColor: color }}
          />
          <h2 className={style.container__group_title}>
            {title}
          </h2>
        </div>
      </div>
      <div className={style.container__buttons}>
        <Button
          className={style.container__buttons_cancel}
          onClick={closeModal}
        >
          {intl.formatMessage({
            id: 'common.cancel',
            defaultMessage: 'Отмена',
          })}
        </Button>
        <Button
          className={style.container__buttons_off}
          color="black"
          onClick={handleDelete}
          disabled={isDisabled}
        >
          {intl.formatMessage({
            id: 'common.delete',
            defaultMessage: 'Удалить',
          })}
        </Button>
      </div>
    </div>
  )
}

export default UserGroupDeleteView
