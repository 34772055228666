import React from 'react'

import { useIntl } from 'react-intl'

import { useStores } from '@/hooks'
import { IStoragePanel } from '@/apps/StorageList/interfaces'

import { FILTER_KEY, EMPTY_CONSTS } from '@/const'
import { IValue } from '@/components/AsyncSelect/interfaces'

import SearchInput from '@/views/SearchInput'
import AsyncSelect from '@/components/AsyncSelect/AsyncSelect'
import CellCounts from '../CellCounts'

import style from './style/index.module.scss'
import { TStorageCellFilterStatus, ICellFilterParams } from '@/api/interfaces'

interface IProps extends IStoragePanel {
  handleParams: (params: ICellFilterParams) => void
  params: ICellFilterParams
}

/**
 * @view
 * * вью для отрисовки панели с поиском и фильтрами для ячеек
 * @param handleParams метод для смены фильтов
 */
const CellPanel: React.FC<IProps> = ({ handleParams, params }) => {
  const { api } = useStores()
  const intl = useIntl()
  /**
   * * метод для фильтрации по типу оборудования
   * @function handleChangeEquipmentType
   * @param selectedType - выбрвный тип оборудования
   */
  const handleChangeEquipmentType = (selectedType: IValue): void => {
    handleParams({ [FILTER_KEY.STORAGE_EQUIPMENT_TYPE]: selectedType.value })
  }
  /**
   * * метод для фильтрации по статусу ячеек
   * @function handleChangeEquipmentType
   * @param selectedCellStatus - выбраный статус ячейки
   */
  const handleChangeCellStausFilter = (selectedCellStatus: TStorageCellFilterStatus): void => {
    handleParams({ [FILTER_KEY.CELL_STATUS]: selectedCellStatus })
  }

  return (
    <div className={style.header}>
      <SearchInput className={style.header__search} handleParams={handleParams} />
      <div className={style.header__right}>
        <div className={style.header__select}>
          <AsyncSelect
            label={intl.formatMessage({
              id: 'storage.cells.typeContent',
              defaultMessage: 'Тип содержимого'
            })}
            handleFetchError={EMPTY_CONSTS.FUNC}
            handleChange={handleChangeEquipmentType}
            placeholder={intl.formatMessage({ id: 'input.all', defaultMessage: 'All' })}
            loadFunction={api.equipment.getEquipmentTypes}
          />
        </div>
        <CellCounts params={params} handleChangeCellStausFilter={handleChangeCellStausFilter} />
      </div>
    </div>
  )
}

export default CellPanel
