import React, { ReactNode } from 'react'

import styles from './styles/index.module.scss'

/**
 * Контейнер для правого сайдбара
 * @view
 *
 * @param [props.children]
 */
const SidebarContainer: React.FC<{
  children: ReactNode
}> = (props) => {
  return (
    <div className={styles['sidebar-container']}>
      {props.children}
    </div>
  )
}
export default SidebarContainer
