import React from 'react'
import { observer } from 'mobx-react'
import { useIntl } from 'react-intl'
import { getCurrentStatus } from '@/apps/StorageList/utils'
import { STORAGE_STATUSES } from '@/apps/StorageList/const'
import { TStorageStatus } from '@/interfaces/consts'
import { Error } from './Error'
import { Succes } from './Success'
import { Synchronization } from './Synchronization'
import style from './style/index.module.scss'

const content = {
  [STORAGE_STATUSES.ERROR]: Error,
  [STORAGE_STATUSES.SUCCES]: Succes,
  [STORAGE_STATUSES.SYNCHRONIZATION_FRONTEND]: Synchronization
}

interface IProp {
  status?: TStorageStatus
}

const ConnectionStatus: React.FC<IProp> = ({ status }) => {
  const intl = useIntl()
  const currentStatus = getCurrentStatus(status ?? STORAGE_STATUSES.SYNCHRONIZATION_FRONTEND)
  const CurrentContent = content[currentStatus]
  return (
    <div className={style.status}>
      <CurrentContent intl={intl} />
    </div>
  )
}

export default observer(ConnectionStatus)
