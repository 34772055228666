import React, { RefObject, UIEvent } from 'react'
import { useIntl } from 'react-intl'

import { isUndefined } from '@/utils'
import { tableHistory } from './style/tableHistory'
import { IColumn } from '@/apps/Table/interfaces'
import {
  IHistoryEquipment,
  IHistoryEquipmentQueryParams
} from '@/api/interfaces'
import {
  HISTORY_EQUIPMENT_COLUMN_KEY,
  HISTORY_EQUIPMENT_FILTER_KEY
} from '@/apps/RightSidebar/const'
import { EMPTY_CONSTS } from '@/const'

import { EquipmentStatusIcon } from '@/components/EquipmentStatusIcon'
import { RenderSortButton } from '@/components/SortButton/RenderSortButton'
import Table from '@/apps/Table/component/Table'
import FullNameBadge from './FullNameBadge'
import HistoryTime from './HistoryTime'
import { Spinner } from '@/views/spinner'
import EmptyHistory from '@/apps/RightSidebar/views/EmptyHistory'
import CellHistoryView from '../../CellHistoryView'

import style from './style/index.module.scss'

export interface IProps {
  historyData?: IHistoryEquipment[]
  handleParams: (
    params: IHistoryEquipmentQueryParams
  ) => void
  params: IHistoryEquipmentQueryParams
  scrollWrapProps: {
    onScroll: (e: UIEvent<HTMLDivElement>) => void
    ref: RefObject<HTMLDivElement>
  }
  isLoading: boolean
}

/**
 * @view
 * * Вью списка истории в модалке оборудования
 * @param historyData       данные истории
 * @param handleParams      метод для смены параметров сортировки
 * @param scrollWrapProps   пропсы для скролющего списка
 * @param params            текущие параметры сортирови
 *  @param isScrollEnd      флаг доскроллено ли до самого низа контейнера
 */
const HistoryListView: React.FC<IProps> = ({
  historyData,
  handleParams,
  scrollWrapProps,
  params,
  isLoading
}) => {
  const intl = useIntl()
  const { ordering } = params

  const column: IColumn[] = [
    {
      key: HISTORY_EQUIPMENT_COLUMN_KEY.FULL_NAME,
      contentHeader: intl.formatMessage({
        id: 'user.fullname',
        defaultMessage: 'ФИО'
      }),
      headerCellProps: {
        render: (title: string) =>
          RenderSortButton(
            title,
            ordering,
            handleParams,
            HISTORY_EQUIPMENT_FILTER_KEY.FULL_NAME
          )
      },
      bodyCellProps: {
        className: style.cell__full_name,
        render: (_, currentRow) => (
          <FullNameBadge
            firstName={currentRow?.first_name}
            lastName={currentRow?.last_name}
            fullName={currentRow?.full_name}
            userRole={currentRow?.user_role}
          />
        )
      }
    },
    {
      key: HISTORY_EQUIPMENT_COLUMN_KEY.UPDATE_AT,
      contentHeader: intl.formatMessage({
        id: 'time',
        defaultMessage: 'Время'
      }),
      headerCellProps: {
        className: style.table__head_time,
        render: (title: string) =>
          RenderSortButton(
            title,
            ordering,
            handleParams,
            HISTORY_EQUIPMENT_FILTER_KEY.UPDATE_AT
          )
      },
      bodyCellProps: {
        className: style.table__body_time,
        render: (time) => <HistoryTime>{time}</HistoryTime>
      }
    },
    {
      key: HISTORY_EQUIPMENT_COLUMN_KEY.CELL_NUMBER,
      contentHeader: intl.formatMessage({
        id: 'cell',
        defaultMessage: 'Ячейка'
      }),
      headerCellProps: {
        render: (title: string) =>
          <div className={style.table__head_cell}>{title}</div>
      },
      bodyCellProps: {
        render: (cell) => (
          <CellHistoryView>{cell}</CellHistoryView>
        )
      }
    },
    {
      key: HISTORY_EQUIPMENT_COLUMN_KEY.PREVIOUS_STATUS,
      contentHeader: intl.formatMessage({
        id: 'equipment.statusBefore',
        defaultMessage: 'Статус до'
      }),
      headerCellProps: {
        className: style.table__head_before,
        render: (title: string) =>
          RenderSortButton(
            title,
            ordering,
            handleParams,
            HISTORY_EQUIPMENT_COLUMN_KEY.PREVIOUS_STATUS
          )
      },
      bodyCellProps: {
        className: style.table__body_before,
        render: (status: string) => (
          <EquipmentStatusIcon type={status} />
        )
      }
    },
    {
      key: HISTORY_EQUIPMENT_COLUMN_KEY.ACTION,
      contentHeader: intl.formatMessage({
        id: 'equipment.action',
        defaultMessage: 'Действие'
      }),
      headerCellProps: {
        render: (title: string) =>
          RenderSortButton(
            title,
            ordering,
            handleParams,
            HISTORY_EQUIPMENT_FILTER_KEY.ACTION
          )
      }
    },
    {
      key: HISTORY_EQUIPMENT_COLUMN_KEY.CURRENT_STATUS,
      contentHeader: intl.formatMessage({
        id: 'equipment.statusAfter',
        defaultMessage: 'Статус после'
      }),
      headerCellProps: {
        render: (title: string) =>
          RenderSortButton(
            title,
            ordering,
            handleParams,
            HISTORY_EQUIPMENT_FILTER_KEY.CURRENT_STATYS
          )
      },
      bodyCellProps: {
        render: (status: string) => (
          <EquipmentStatusIcon type={status} />
        )
      }
    }
  ]

  if (
    isLoading &&
    (isUndefined(historyData) ||
      historyData.length === EMPTY_CONSTS.ZERO)
  ) {
    return <Spinner isFullPage />
  }

  if (
    !isLoading &&
    historyData?.length === EMPTY_CONSTS.ZERO
  ) {
    return <EmptyHistory />
  }

  return (
    <div
      className={style.scroll}
      {...scrollWrapProps}
    >
      <Table
        columns={column}
        rowsData={historyData}
        styles={tableHistory}
      />
    </div>
  )
}

export default HistoryListView
