import { RootStore } from './Root'
import { makeAutoObservable } from 'mobx'
import { ISocketIntegrationData } from '@/interfaces/Socket'
/**
 * @class IntegrationEventSocket
 * * класс для обработки ивентов интеграции (скуд) по сокетам
 */
export class IntegrationEventsSocket {
  _rootStore: RootStore
  integration: ISocketIntegrationData | null

  constructor (RootStore: RootStore) {
    this._rootStore = RootStore
    this.integration = null
    makeAutoObservable(this)
  }

  public integrationEventsSubscription = ({
    data
  }: {
    data: ISocketIntegrationData
  }): void => {
    this.integration = data
  }

  public clear = (): void => {
    this.integration = null
  }
}
