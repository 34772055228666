import React from 'react'
import { useIntl } from 'react-intl'

import Icon from '@/components/Icon'
import { Button } from '@/views/button'
import DateInput from '@/components/DateInput'

import styles from './styles/index.module.scss'

export interface IDateInputsProps {
  startDate?: string
  endDate?: string
  handleStartChange: (date: string) => void
  handleEndChange: (date: string) => void
  handleDownload?: () => void
  isReportLoading: boolean
}

/**
 * Вьюха для выбора периода с/по и кнопкой формирования отчета за этот переиод
 * @param handleStartChange - обработчик на изменение стартовой даты
 * @param handleEndChange - обработчик на изменение конечной даты
 * @param handleDownload - обработчик на скачивание даты
 * @constructor
 */

const DateInputs: React.FC<IDateInputsProps> = ({
  startDate,
  endDate,
  handleStartChange,
  handleEndChange,
  handleDownload,
  isReportLoading
}) => {
  const intl = useIntl()
  return (
    <div className={styles.datepicker__root}>
      <div className={styles.datepicker__fields}>
        <DateInput
          date={startDate}
          handleDateChange={handleStartChange}
        />
        <span className={styles.datepicker__divider} />
        <DateInput
          date={endDate}
          handleDateChange={handleEndChange}
        />
      </div>
      <Button
        disabled={isReportLoading}
        color='transparent'
        className={styles.datepicker__button}
        onClick={handleDownload}
      >
        <Icon src='download' size='small-xl' />
        {intl.formatMessage({
          id: 'generateReport',
          defaultMessage: 'Сформировать отчет'
        })}
      </Button>
    </div>
  )
}

export default DateInputs
