import React from 'react'

import { useIntl } from 'react-intl'
import { IEquipment } from '@/api/interfaces'
import { MODAL_VARIANT_CONTENT } from '../../const'
import { IModalVariant } from '../../ModalContent/interfaces'

import Icon from '@/components/Icon'
import { Button } from '@/views/button'
import { EquipmentStatusIcon } from '@/components/EquipmentStatusIcon'

import style from './style/index.module.scss'
import { observer } from 'mobx-react'

interface IProps {
  equipmentData: IEquipment
  modalType?: IModalVariant
  closeModal: () => void
  handleSubmit: () => void
  isDisabled?: boolean
}
/**
 * @view
 * * вьюха для отрисовки модалки удаления/отвязкти оборудования в
 * * зависимости от ее типа
 * @param modalType - тип отоброжаемого контента
 * @param equipmentData текущее оборудование
 * @param closeModal метод закртия модалкти
 * @param handleSubmit метод для какого либо запроса
 * @param isDisabled флаг для выкл кнопки сабмита
 */
const EquipmentUntieView: React.FC<IProps> = ({
  modalType = MODAL_VARIANT_CONTENT.DELETE,
  equipmentData,
  closeModal,
  handleSubmit,
  isDisabled
}) => {
  const intl = useIntl()
  const {
    equipment_type: type,
    title,
    status
  } = equipmentData

  const isDeleteModal =
    modalType === MODAL_VARIANT_CONTENT.DELETE
  const btnText = isDeleteModal
    ? { id: 'common.delete', defaultMessage: 'Удалить' }
    : { id: 'common.unbind', defaultMessage: 'Отвязать' }
  const imgType = isDeleteModal ? 'delete' : 'linkOff'
  const titleText = isDeleteModal
    ? {
        id: 'modal.title.equipment_delete',
        defaultMessage:
      'Вы точно хотите удалить оборудование?'
      }
    : {
        id: 'modal.title.equipment_unbind',
        defaultMessage:
      'Вы точно хотите отвязать оборудование?'
      }

  return (
    <div className={style.container}>
      <div className={style.container__header}>
        <Icon
          src={imgType}
          size='xl'
          className={style.container__header_icon}
          data-type={modalType}
        />
        <p className={style.container__header_lable}>
          {intl.formatMessage(titleText)}
        </p>
      </div>
      <div className={style.container__equipment}>
        <div className={style.container__equipment__body}>
          <span
            className={style.container__equipment__body_sub}
          >
            {type}
          </span>
          <h2
            className={style.container__equipment_title}
          >
            {title}
          </h2>
        </div>
        <EquipmentStatusIcon type={status} />
      </div>
      <div className={style.container__buttons}>
        <Button
          className={style.container__buttons_cancel}
          onClick={closeModal}
        >
          {intl.formatMessage({
            id: 'common.cancel',
            defaultMessage: 'Отмена'
          })}
        </Button>
        <Button
          className={style.container__buttons_off}
          color='black'
          onClick={handleSubmit}
          disabled={isDisabled}
        >
          {intl.formatMessage(btnText)}
        </Button>
      </div>
    </div>
  )
}

export default observer(EquipmentUntieView)
