import React, { FC } from 'react'
import { useIntl } from 'react-intl'
import { useStores } from '@/hooks'
import { isNull, isUndefined } from '@/utils'

import { RIGHT_SIDEBAR_TYPES } from '@/apps/RightSidebar/const'
import { IEquipmentTable } from '../interfaces'
import { IEquipment } from '@/api/interfaces'
import { IRowData, TOnRow } from '@/apps/Table/interfaces'
import { tableStyles } from '../const'
import {
  DEFAULT_ERROR_ARRAY,
  EMPTY_CONSTS,
  EQUIPMENT_KEY,
  TABLE_WRAPPER_ICON_SRC,
  EQUIPMENT_FILTER_KEY,
  TABLE_PAGE
} from '@/const'
import Table from '@/apps/Table/component/Table'
import { RenderSortButton } from '@/components/SortButton/RenderSortButton'
import Pagination from '@/components/Pagination'
import * as EquipComp from './EquipTableComponent'
import TableWrapper from '@/views/TableWrapper'
import EditWithTime from '@/apps/StorageList/views/EditWithTime'
import styles from '../style/index.module.scss'

/**
 * @component EquipmentTable
 * * компонент таблицы оборудования
 *
 * @param equipments               массив оборудований
 * @param params                   текущие query параметры
 * @param handleParams             установить дополнительные параметры к текущим query параметрам
 * @param isLoading                статус загрузки данных с сервера
 * @param currentPage               номер текущей страницы
 * @function handlePage            функция меняющая номер страницы
 * @param total                    общее количество оборудований
 * @param error                    ошибки, полученные при запросе оборудования
 */
const EquipmentTable: FC<IEquipmentTable> = ({
  params,
  handleParams,
  equipments,
  isLoading,
  currentPage,
  handlePage,
  total,
  error = DEFAULT_ERROR_ARRAY,
  refetch
}) => {
  const intl = useIntl()
  const { rightSideBarStore } = useStores()
  /**
   * * функиця для открытия деталки оборудования
   * @func handleOpenUserSideBar
   * @param equipment - данные о кликнутом оборудовании
   */
  const handleOpenUserSideBar = (
    equipment: IRowData
  ): void => {
    rightSideBarStore.open(
      RIGHT_SIDEBAR_TYPES.EQUIPMENT_SIDEBAR,
      { equipmentId: equipment.id, refetch }
    )
  }

  const onRow: TOnRow = (rowData) => ({
    onClick: () => handleOpenUserSideBar(rowData)
  })

  const columns = [
    {
      key: EQUIPMENT_KEY.TITLE,
      contentHeader: intl.formatMessage({
        id: 'equipment.title',
        defaultMessage: 'Название'
      }),
      headerCellProps: {
        render: (title: string) =>
          RenderSortButton(
            title,
            params?.ordering,
            handleParams,
            EQUIPMENT_FILTER_KEY.TITLE
          )
      },
      bodyCellProps: { render: EquipComp.Title }
    },
    {
      key: EQUIPMENT_KEY.STORAGE,
      contentHeader: intl.formatMessage({
        id: 'storage',
        defaultMessage: 'Шкаф'
      }),
      headerCellProps: {
        className: styles.header__storage,
        render: (title: string) =>
          RenderSortButton(
            title,
            params?.ordering,
            handleParams,
            EQUIPMENT_FILTER_KEY.STORAGE
          )
      },
      bodyCellProps: {
        render: (_, props?: IEquipment) => {
          return EquipComp.StorageTitle(
            !isUndefined(props) && !isNull(props.storage)
              ? props.storage.title
              : EMPTY_CONSTS.STR
          )
        }
      }
    },
    {
      key: EQUIPMENT_KEY.SERIAL_NUMBER,
      contentHeader: intl.formatMessage({
        id: 'equipment.serial_number',
        defaultMessage: 'Серийный номер'
      }),
      headerCellProps: {
        render: (title: string) =>
          RenderSortButton(
            title,
            params?.ordering,
            handleParams,
            EQUIPMENT_FILTER_KEY.SERIAL_NUMBER
          )
      }
    },
    {
      key: EQUIPMENT_KEY.UPDATED_AT,
      contentHeader: intl.formatMessage({
        id: 'equipment.updated_at',
        defaultMessage: 'Изменено'
      }),
      headerCellProps: {
        render: (title: string) =>
          RenderSortButton(
            title,
            params?.ordering,
            handleParams,
            EQUIPMENT_FILTER_KEY.UPDATED_AT
          )
      },
      bodyCellProps: {
        render: (date: string) => (
          <EditWithTime date={date} />
        )
      }
    },
    {
      key: EQUIPMENT_KEY.STATUS,
      contentHeader: intl.formatMessage({
        id: 'equipment.status',
        defaultMessage: 'Статус'
      }),
      headerCellProps: {
        render: (title: string) =>
          RenderSortButton(
            title,
            params?.ordering,
            handleParams,
            EQUIPMENT_FILTER_KEY.STATUS
          )
      },
      bodyCellProps: { render: EquipComp.Status }
    },
    {
      key: EQUIPMENT_KEY.ID,
      contentHeader: EMPTY_CONSTS.STR,
      bodyCellProps: {
        render: (_: number, equipment: IEquipment) =>
          EquipComp.EquipId(equipment, refetch)
      }
    }
  ]

  return (
    <TableWrapper
      iconSrc={TABLE_WRAPPER_ICON_SRC.EQUIPMENT}
      search={params?.search}
      isLoading={isLoading}
      type={TABLE_PAGE.EQUIPMENT}
      error={error}
      length={equipments?.length}
    >
      <div className={styles.container__table}>
        <Table
          onRow={onRow}
          columns={columns}
          rowsData={equipments}
          styles={tableStyles}
        />
        <Pagination
          className={styles.container__pagination}
          currentPage={currentPage}
          handleChange={handlePage}
          total={total}
          pageSize={params?.page_size}
        />
      </div>
    </TableWrapper>
  )
}

export default EquipmentTable
