import AbsractApi from '@/api/abstract'
import {
  IGetAccesControlIntegrationValues,
  TAccesControlIntegrationValues,
} from '@/api/interfaces/Integration'
import { EMPTY_CONSTS } from '@/const'

class IntegrationApi {
  private readonly _ctx: AbsractApi

  constructor(AbsractApi: AbsractApi) {
    this._ctx = AbsractApi
  }

  public getAccessControllValues =
    async (): Promise<TAccesControlIntegrationValues> => {
      const res =
        await this._ctx.get<IGetAccesControlIntegrationValues>(
          '/integration/access_control/',
        )
      // FIXME типы ответа
      return res.data
    }

  public changeAccessControllValues = async (
    value: TAccesControlIntegrationValues,
  ): Promise<void> => {
    await this._ctx.patch(
      '/integration/access_control/',
      value,
    )
  }

  public forcedSynchronization =
    async (): Promise<void> => {
      await this._ctx.post(
        '/integration/access_control/synch/',
        {},
      )
    }

  public unlockAllUsers = async (): Promise<void> => {
    await this._ctx.post(
      '/users/unblock_all/',
      EMPTY_CONSTS.OBJ,
    )
  }
}

export default IntegrationApi
