import { makeAutoObservable } from 'mobx'
import { RootStore } from './Root'
import { INotification } from '@/api/interfaces'
import { IDownloadReport } from '@/apps/Toast/interfaces'

type TNotification = INotification &
IDownloadReport

export class NotifyStore {
  variant: string | null
  rootStore: RootStore
  notifications: TNotification[]

  constructor (rootStore: RootStore) {
    this.rootStore = rootStore
    this.variant = null
    this.notifications = []
    makeAutoObservable(this)
  }

  createNotification = (props: TNotification): void => {
    this.notifications.push({
      ...props
    })
  }

  setClosing = (index: number): void => {
    this.notifications[index] = {
      ...this.notifications[index],
      closing: true
    }
  }

  deleteNotification = (index: number): void => {
    this.notifications.splice(index, 1)
  }
}
