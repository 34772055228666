import React, { JSX } from 'react'

import Media from 'react-media'

import StorageStatus from '../StorageStatus'
import { Typography } from '@/views/typography'
import EditWithTime from '../EditWithTime'

import { IStorage } from '@/api/interfaces'

import { MEDIA_QUERY } from '@/const'

import styles from '../../styles/index.module.scss'

/**
 * @component CountCells
 * * компонент статуса шкафа c номером ячейки
 * @param
 */
export const CountCells = (_: string, storage: IStorage): JSX.Element => {
  return (
    <StorageStatus
      storageStatus={storage?.storage_status ?? false}
      countCells={storage?.count_cells}
    />
  )
}

/**
 * @component Title
 * * компонент названия шкафа
 * @param
 */
export const Title = (title: string, storage: IStorage): JSX.Element => {
  return (
    <div className={styles.table__body_titlewrap}>
      <Typography
        className={styles.table__body_title}
        size={18}
        weight={700}
      >
        {title}
      </Typography>
      <Media queries={{ large: MEDIA_QUERY.LARGE }}>
        {({ large }) => (
          <>
            {!large &&
              <EditWithTime
                date={storage?.updated_at ?? false}
              />}
          </>
        )}
      </Media>
    </div>
  )
}

/**
 * @component UpdatedAt
 * * компонент даты обновления оборудования
 * @param
 */
export const Description = (description: string): JSX.Element => {
  return (
    <Typography
      className={styles.table__body_description}
      color='gray'
      size={14}
      weight={400}
    >
      {description}
    </Typography>
  )
}

/**
 * @component UpdatedAt
 * * компонент даты обновления оборудования
 * @param
 */
export const UpdatedAt = (_: string, storage: IStorage): JSX.Element => {
  return (
    <EditWithTime
      date={storage?.updated_at}
    />
  )
}
