import React, { FC } from 'react'
import classNames from 'classnames'
import { IButton } from '@/interfaces/Common'

import styles from './style/index.module.scss'
import Loader from '../Loader'

/**
 * @component Button
 * * компонент кпопки
 * @param {IButton}                        общий интерфейс входных параметров Button
 *
 * @param onClick                          функция которая выполнится при нажатии
 * @param color                            цвет кнопки
 * @param positionLabel                    позиционирование текста внутри кнопки
 * @param type                             тип кнопки
 * @param children                         дочерние компоненты (контент)
 * @param className                        дополнительные стили
 * @param isDashed                         штрихпунктирная кнопка
 * @param weight                           отдельно задать толщину текста
 * @param variant                          готовый вариант кнопки
 * @param load                             замена контента кнопки на лоадер
 */
export const Button: FC<IButton> = ({
  color = 'default',
  positionLabel = 'center',
  children,
  type = 'button',
  className,
  isDashed = false,
  weight = 'normal',
  variant = '',
  load = false,
  ref,
  ...props
}) => {
  return (
    <button
      ref={ref}
      className={classNames(
        styles.button,
        styles[`button__${color}`],
        styles[`button__position_${positionLabel}`],
        styles[`button__weight--${weight}`],
        {
          [styles.button__dashed]: isDashed,
          [styles[`button__variant--${variant}`]]: Boolean(variant)
        },
        className
      )}
      type={type}
      {...props}
    >
      {load
        ? (
          <div className={styles.loader}>
            <Loader size='medium' />
          </div>)
        : null}
      {children}
    </button>
  )
}
