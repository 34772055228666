import React from 'react'

import BottomFields from './BottomFields'
import TopFields from './TopFields'
import IntegrationSettings from './IntegrationSettings'
import FormCheckbox from '../../components/FormCheckbox'

import { CHECKBOX_FIELDS } from '../../const'

import styles from './style/index.module.scss'
/**
 * @view
 * * вьюха с главными полями интеграции
 */
const CoreForm = (): React.ReactElement => {
  return (
    <div className={styles.wrap}>
      <TopFields />
      <div className={styles.wrap__checkboxs}>
        {CHECKBOX_FIELDS.map(({ id, name, label }) => (
          <FormCheckbox
            key={id}
            nameField={name}
            label={label}
            className={styles['checkbox-lable']}
          />
        ))}
      </div>
      <BottomFields />
      <IntegrationSettings />
    </div>
  )
}

export default CoreForm
