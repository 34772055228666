import React, { memo } from 'react'

import useTranslate, {
  TMessage
} from '@/hooks/useTranslate'

import { COUNTER_STORAGE_COLOR } from '@/apps/MainSidebar/const'

import Icon from '@/components/Icon'
import { Typography } from '@/views/typography'

import style from './style/index.module.scss'
import classNames from 'classnames'

interface IProps {
  iconName: string
  count: number
  title: TMessage
  isCustomVisually?: boolean
  optionsVisually?: { classname: string }
}

/**
 * @view
 * * вью реализующий интерфейс каунтера - его название фото и колличество чего либо
 * @param iconName название картинки
 * @param count    количество текущего чего либо
 * @param title    заголовок текущего каунтера
 */
const Counter: React.FC<IProps> = ({
  iconName,
  count,
  title,
  isCustomVisually,
  optionsVisually
}) => {
  const { translate } = useTranslate()
  const numberColor =
    count > 0
      ? COUNTER_STORAGE_COLOR.ORANGE
      : COUNTER_STORAGE_COLOR.GRAY

  const customClassName =
    optionsVisually != null && isCustomVisually === true
      ? style[optionsVisually?.classname]
      : ''
  return (
    <div className={style.counter}>
      <Icon
        className={style.counter__icon}
        src={iconName}
      />
      <Typography size={16} weight={400}>
        {translate(title)}
      </Typography>
      <span
        data-color={numberColor}
        className={classNames(style.counter__number, {
          [customClassName]: isCustomVisually
        })}
      >
        {count}
      </span>
    </div>
  )
}

export default memo(Counter)
