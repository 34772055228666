import React from 'react'
import { observer } from 'mobx-react'
import EditDropdown from '@/views/EditDropdown'
import { useStores } from '@/hooks'
import { MODAL_TYPES } from '@/const'
import { IGroupList } from '@/api/interfaces/GroupUsers'
/**
 * @view
 * Вюха дропдауна с контентом для группы пользователей
 * ! Пока заглущка - нужна для таски с редактированием группы
 * @returns
 */

interface IProps {
  groupUser: IGroupList
  refetch: () => void
}

const GroupDropdown: React.FC<IProps> = ({
  groupUser,
  refetch,
}) => {
  const { modalStore } = useStores()

  const handleOpenGroupEditModal = () => {
    modalStore.open(MODAL_TYPES.USER_GROUP_EDIT, {
      groupId: groupUser.id,
      refetch,
    })
  }

  const handleDeleteUserGroup = () => {
    modalStore.open(MODAL_TYPES.USER_GROUP_DELETE, {
      userGroup: {
        id: groupUser.id,
        title: groupUser.title,
        color: groupUser.color,
      },
      refetch,
    })
  }

  return (
    <EditDropdown
      hadnleEdit={handleOpenGroupEditModal}
      handleDelete={handleDeleteUserGroup}
    />
  )
}

export default observer(GroupDropdown)
