import React, { FC } from 'react'
import { useIntl } from 'react-intl'

import { useStores } from '@/hooks'

import Icon from '@/components/Icon'
import Badge from '@/components/Badge'
import { Typography } from '@/views/typography'
import { Button } from '@/views/button'

import { getInitials, isUndefined } from '@/utils'

import {
  DEFAULT_USER_ROLE,
  EMPTY_CONSTS,
  MODAL_CUSTOM_CONTENT,
  MODAL_TYPES,
} from '@/const'

import { IUserModal } from '../interfaces'

import style from '../styles/index.module.scss'

/**
 * * компонент модального окна удаления пользователя
 * @component UserDelete
 *
 * @param modalProps - пропсы для текущей модалки
 * @func  closeModal - функция закрытия модалки
 * @returns
 */
const UserDelete: FC<IUserModal> = ({
  modalProps,
  closeModal,
}) => {
  const {
    id,
    full_name: fullName,
    last_name: lastName = EMPTY_CONSTS.STR,
    first_name: firstName = EMPTY_CONSTS.STR,
    user_role: userRole = DEFAULT_USER_ROLE,
    refetch,
  } = modalProps
  const title = getInitials(firstName, lastName)
  const intl = useIntl()
  const { api, modalStore } = useStores()

  /**
   * @func onSubmit
   * * функция реализующая логику удаления пользователя
   */
  const onSubmit = (): void => {
    void api.user
      .deleteUser(id)
      .then(() => {
        closeModal()
        refetch()
      })
      .catch((err) => {
        const error = err.response?.data?.error
        if (!isUndefined(error)) {
          modalStore.open(MODAL_TYPES.WARNING_ERROR, {
            error: [error],
          })
        } else {
          modalStore.open(
            MODAL_TYPES.CUSTOM,
            MODAL_CUSTOM_CONTENT.ERROR,
          )
        }
      })
  }

  return (
    <div className={style.container}>
      <div className={style.container__header}>
        <Icon
          src="delete"
          size="xl"
          className={style.container__header_icon}
        />
        <h2 className={style.title}>
          {intl.formatMessage({
            id: 'modal.title.user_delete',
            defaultMessage:
              'Are you sure you want to delete the user?',
          })}
        </h2>
      </div>
      <div className={style.container__user}>
        <div className={style.container__user_body}>
          <Badge title={title} />
          <Typography
            weight={700}
            size={18}
            className={style.container__user_text}
          >
            {fullName ?? '-'}
          </Typography>
        </div>
        {userRole !== undefined &&
          intl.formatMessage({
            id: `user.user_role.${userRole}`,
            defaultMessage: 'role',
          })}
      </div>
      <div className={style.container__buttons}>
        <Button
          className={style.container__buttons_cancel}
          onClick={closeModal}
        >
          {intl.formatMessage({
            id: 'common.cancel',
            defaultMessage: 'Cancel',
          })}
        </Button>
        <Button
          className={style.container__buttons_off}
          color="black"
          onClick={onSubmit}
        >
          {intl.formatMessage({
            id: 'common.delete',
            defaultMessage: 'Delete',
          })}
        </Button>
      </div>
    </div>
  )
}

export default UserDelete
