import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Button } from '@/views/button'
import Icon from '@/components/Icon'
import styles from '../styles/index.module.scss'
import classNames from 'classnames'

/**
 * @component SuccessRecoveryPassword
 * * компонент формы успешного востановления пароля
 */
const SuccessRecoveryPassword: React.FC<any> = ({
  closeModal
}): React.ReactElement => {
  return (
    <div
      className={styles.container_block}
    >
      <div className={classNames(styles.container, styles['container__recovery-password'])}>
        <Button
          className={styles.container__close}
          onClick={closeModal}
          variant='text'
          color='gray'
        >
          <Icon src='close' />
        </Button>
        <Icon size='' xxxxl src='successStatus' />
        <span className={styles['container__recovery-password_title']}>
          <FormattedMessage
            id='profile.modal.succesEditPassword'
            defaultMessage='Ваш пароль успешно изменен!'
          />
        </span>
      </div>
    </div>
  )
}

export default SuccessRecoveryPassword
