import React from 'react'

import { observer } from 'mobx-react'
import { useNavigate } from 'react-router-dom'

import { useLeftSidebar, usePage } from '@/hooks'
import { getCurrentStatus } from '@/apps/StorageList/utils'
import { STORAGE_STATUSES } from '@/apps/StorageList/const'
import { ArrowRightIcon } from '@/assets/icon'

import AllCells from './AllCells'
import Icon from '@/components/Icon'
import Loader from '@/views/Loader'
import { Typography } from '@/views/typography'

import style from './style/index.module.scss'
import { getTitleStatusBarByStatus } from '@/apps/MainSidebar/utils'
import { Button } from '@/views/button'

const content = {
  [STORAGE_STATUSES.SUCCES]: () => <AllCells />,
  [STORAGE_STATUSES.ERROR]: () => <Icon className={style.icon} src='statusNotConnected' />,
  [STORAGE_STATUSES.SYNCHRONIZATION_FRONTEND]: () => (
    <Loader className={style.loader} size='medium' />
  )
}

interface IProps {
  status: string
}
/**
 * @view
 * * вью шапки деталки шкафа, показывающий текущий статус шкафа
 * @param status - статус шкафа с бэкенда
 * @returns
 */
const HeaderStatus: React.FC<IProps> = ({ status }) => {
  const navigate = useNavigate()
  const { baseSlug } = usePage()
  const { handleCloseLeftSidebar } = useLeftSidebar()
  const handleClose = (): void => {
    handleCloseLeftSidebar(() => { navigate(baseSlug.storageListPage) })
  }

  const currStatus = getCurrentStatus(status)
  const title = getTitleStatusBarByStatus(currStatus)

  const CurrentContent = content[currStatus]
  return (
    <div data-status={currStatus} className={style.head}>
      <Button
        className={style.buttontoback}
        color='transparent'
        onClick={handleClose}
      >
        <img
          className={style.buttontoback__icon}
          src={ArrowRightIcon}
          alt='Закрыть'
        />
      </Button>
      <Typography className={style.head__title} size={14}>
        {title}
      </Typography>
      <div className={style.head__right}>
        <CurrentContent />
      </div>
    </div>
  )
}

export default observer(HeaderStatus)
